import { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import {
  ErrorCode,
  FileRejection,
  FileWithPath,
  useDropzone,
} from 'react-dropzone';

import { InlineFormError, Modal } from '@/shared/ui';
import {
  UPLOADING_DENTAL_PHOTO_MAX_SIZE,
  UPLOADING_IMAGE_MAX_FILES,
} from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';

import { ModalID, modalModel } from '@/entities/modal';

import { UploadPhotoFile } from '@/features/uploadDentalPhotos/ui/UploadPhotoFile/UploadPhotoFile.tsx';
import { useUploadAssetContext } from '@/features/uploadAsset';

import { UploadDentalPhotosDropzone } from '../UploadDentalPhotosDropzone/UploadDentalPhotosDropzone.tsx';

import styles from './UploadDentalPhotosModal.module.scss';

type UploadDentalPhotosModalProps = {
  patientID: string;
  className?: string;
  testID?: string;
};

export const UploadDentalPhotosModal: FC<UploadDentalPhotosModalProps> = (
  props,
) => {
  const { className, testID, patientID } = props;

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { startUploadAsset: startUploadPhoto } = useUploadAssetContext();

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { visible } = useAppSelector(
    modalModel.selectors.selectDentalPhotosUploadModal,
  );

  const handleUpload = async () => {
    dispatch(modalModel.actions.closeModal(ModalID.DentalPhotosUpload));

    await startUploadPhoto({
      patientID,
      files,
      assetType: AssetType.AssetType_Study_DentalPhoto,
    });

    setFiles([]);
  };

  const handleClose = () => {
    dispatch(modalModel.actions.closeModal(ModalID.DentalPhotosUpload));
  };

  const handleAcceptedFiles = useCallback((acceptedFiles: FileWithPath[]) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    setFiles((prevFiles) => prevFiles.concat(acceptedFiles));
  }, []);

  const handleRejectFiles = useCallback(
    (fileRejections: FileRejection[]) => {
      let currentErrorMessage: string;

      if (fileRejections.length > 0) {
        const errorCode = fileRejections?.at(0)?.errors?.at(0)?.code;

        switch (errorCode) {
          case ErrorCode.FileInvalidType: {
            currentErrorMessage = formatMessage({
              id: 'orderReportModal.wrongFile',
              defaultMessage: 'Wrong File(s) format',
            });
            break;
          }
          case ErrorCode.FileTooLarge: {
            currentErrorMessage = formatMessage(
              {
                id: 'orderReportModal.sizeError',
                defaultMessage: 'File(s) size must be less than {maxSize}',
              },
              { maxSize: '25MB' },
            );
            break;
          }
          default: {
            currentErrorMessage = formatMessage({
              id: 'uploadImage.wrongFile',
              defaultMessage: 'Some files are too large or incorrect format',
            });
            break;
          }
        }

        setErrorMessage(currentErrorMessage);
      }
    },
    [formatMessage],
  );

  const dropzoneState = useDropzone({
    maxSize: UPLOADING_DENTAL_PHOTO_MAX_SIZE,
    maxFiles: UPLOADING_IMAGE_MAX_FILES,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'application/zip': ['.zip'],
    },
    onDropRejected: handleRejectFiles,
    onDropAccepted: handleAcceptedFiles,
  });

  useEffect(() => {
    setFiles([]);
    setErrorMessage('');
  }, [visible]);

  return (
    <Modal
      isOpen={visible}
      title={formatMessage({
        id: 'dentalPhotos.upload.title',
        defaultMessage: 'Upload photos',
      })}
      okButtonProps={{ disabled: files.length === 0 }}
      okButtonText={formatMessage({
        id: 'global.upload',
        defaultMessage: 'Upload',
      })}
      onOk={handleUpload}
      onCancel={handleClose}
    >
      <div data-testid={testID} className={cn(styles.container, className)}>
        {files.length > 0 &&
          files.map((file) => (
            <UploadPhotoFile
              file={file}
              onDelete={() =>
                setFiles((prevFiles) =>
                  prevFiles.filter((prevFile) => prevFile.name !== file.name),
                )
              }
            />
          ))}

        <UploadDentalPhotosDropzone dropzoneState={dropzoneState} />

        {!!errorMessage && <InlineFormError errorMessage={errorMessage} />}
      </div>
    </Modal>
  );
};
