import { includes } from 'ramda';

import { getTextTagsFromHTML } from './getTextTagsFromHTML';

type RawDraftContentBlock = {
  data?: {
    'text-align'?: string;
  };
};

export const getDraftJsStyles = async (editorValue: string) => {
  const [{ convertToRaw }, { stateFromHTML }] = await Promise.all([
    import('draft-js'),
    import('draft-js-import-html'),
  ]);

  const tags = getTextTagsFromHTML(editorValue);
  const raw = convertToRaw(stateFromHTML(editorValue));

  raw.blocks.forEach((block: RawDraftContentBlock, index) => {
    const currentTag = tags[index];

    if (!block?.data || !currentTag) {
      return;
    }

    if (includes('ql-align-center', currentTag)) {
      block.data['text-align'] = 'center';
    } else if (includes('ql-align-right', currentTag)) {
      block.data['text-align'] = 'right';
    } else if (includes('ql-align-justify', currentTag)) {
      block.data['text-align'] = 'justify';
    }
  });

  return raw;
};
