// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/core/svc_album.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { CreateAlbumReq, CreateAlbumResp, DeleteAlbumReq, DeleteAlbumResp, DeleteStudiesFromAlbumReq, DeleteStudiesFromAlbumResp, SetMainInfoReq, SetMainInfoResp } from "./svc_album_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.diagnocat.core.AlbumService
 */
export const AlbumService = {
  typeName: "com.diagnocat.core.AlbumService",
  methods: {
    /**
     * Create new album
     *
     * Permission: `PatientPermissions.CanEditAlbums`
     *
     * @generated from rpc com.diagnocat.core.AlbumService.CreateAlbum
     */
    createAlbum: {
      name: "CreateAlbum",
      I: CreateAlbumReq,
      O: CreateAlbumResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set Name && Time
     *
     * Permission: `PatientPermissions.CanEditAlbums`
     *
     * @generated from rpc com.diagnocat.core.AlbumService.SetMainInfo
     */
    setMainInfo: {
      name: "SetMainInfo",
      I: SetMainInfoReq,
      O: SetMainInfoResp,
      kind: MethodKind.Unary,
    },
    /**
     * Delete album
     *
     * Permission: `PatientPermissions.CanEditAlbums`
     *
     * @generated from rpc com.diagnocat.core.AlbumService.DeleteAlbum
     */
    deleteAlbum: {
      name: "DeleteAlbum",
      I: DeleteAlbumReq,
      O: DeleteAlbumResp,
      kind: MethodKind.Unary,
    },
    /**
     * Delete album
     *
     * Permission: `PatientPermissions.CanEditAlbums`
     *
     * @generated from rpc com.diagnocat.core.AlbumService.DeleteStudiesFromAlbum
     */
    deleteStudiesFromAlbum: {
      name: "DeleteStudiesFromAlbum",
      I: DeleteStudiesFromAlbumReq,
      O: DeleteStudiesFromAlbumResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

