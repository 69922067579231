import { useEffect } from 'react';
import { isEqual } from 'lodash-es';

import { LocalStorageKey } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { organizationModel } from '@/entities/organization';

export const useSyncOrganizationID = () => {
  const dispatch = useAppDispatch();

  const allOrganizationsIDs = useAppSelector(
    organizationModel.selectors.selectIds,
    isEqual,
  ) as string[];

  const currentOrganizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  useEffect(() => {
    const lastOrganizationID =
      localStorage.getItem(LocalStorageKey.organizationID) ?? '';

    const isOrganizationsExist = !!allOrganizationsIDs?.length;

    const isLastOrganizationIDAvailable =
      allOrganizationsIDs.includes(lastOrganizationID);

    if (isOrganizationsExist) {
      if (isLastOrganizationIDAvailable) {
        // set lastOrganization ID as currentOrganizationID
        dispatch(
          organizationModel.thunks.setCurrentOrganizationByID(
            lastOrganizationID,
          ),
        );
      } else {
        // set first ID of allOrganizationsIDs as currentOrganizationID
        const newOrganizationID = allOrganizationsIDs[0];

        dispatch(
          organizationModel.thunks.setCurrentOrganizationByID(
            newOrganizationID,
          ),
        );
      }
    }
  }, [allOrganizationsIDs, currentOrganizationID, dispatch]);
};
