import { range } from 'lodash-es';

import { Localization } from '@/shared/api/protocol-ts/model/dto_report_localization_pb';

export type LocalizationWithISO = Localization & {
  ISONumber: number;
  SupernumeraryIndex: number;
  toothID?: string;
  isRemoved?: boolean;
};

// Can we unite and unify this with condition colors?
export type ToothType =
  | 'unhealthy'
  | 'lowProbability'
  | 'treated'
  | 'healthy'
  | 'missing'
  | 'all';

export type ToothTypesSet = Record<string, ToothType>;

export enum DentalNotations {
  ISO = 'ISO',
  UNIVERSAL = 'UNIVERSAL',
}

export type DentalNotation = keyof typeof DentalNotations;

export type TeethChartFilter = {
  type: ToothType;
  title: string;
  ISONumbers: number[];
  active: boolean;
};

export type ToothWithLocalization = {
  toothID: string;
  ISONumber: number;
  SupernumeraryIndex?: number;
  isRemoved?: boolean;
  Localization: Localization;
};

export type RudimentTeeth =
  | 55
  | 54
  | 53
  | 52
  | 51
  | 61
  | 62
  | 63
  | 64
  | 65
  | 71
  | 72
  | 73
  | 74
  | 75
  | 85
  | 84
  | 83
  | 82;

export interface JawQuartilesRanges {
  [key: string]: number[];
}

export enum JawQuartiles {
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
  topLeftPrimary = 'topLeftPrimary',
  topRightPrimary = 'topRightPrimary',
  bottomLeftPrimary = 'bottomLeftPrimary',
  bottomRightPrimary = 'bottomRightPrimary',
}

export const jawQuartilesRanges: JawQuartilesRanges = {
  [JawQuartiles.topLeft]: range(18, 10),
  [JawQuartiles.topRight]: range(21, 29),
  [JawQuartiles.bottomLeft]: range(48, 40),
  [JawQuartiles.bottomRight]: range(31, 39),
  topLeftPrimary: range(55, 50),
  topRightPrimary: range(61, 66),
  bottomLeftPrimary: range(85, 80),
  bottomRightPrimary: range(71, 76),
};

export const UPPER_JAW_PERMANENT = [
  ...jawQuartilesRanges.topLeft,
  ...jawQuartilesRanges.topRight,
];

export const UPPER_JAW_PRIMARY = [
  ...jawQuartilesRanges.topLeftPrimary,
  ...jawQuartilesRanges.topRightPrimary,
];

export const LOWER_JAW_PERMANENT = [
  ...jawQuartilesRanges.bottomLeft,
  ...jawQuartilesRanges.bottomRight,
];

export const LOWER_JAW_PRIMARY = [
  ...jawQuartilesRanges.bottomLeftPrimary,
  ...jawQuartilesRanges.bottomRightPrimary,
];
