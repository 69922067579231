// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/core/svc_upload.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { Empty, proto3 } from "@bufbuild/protobuf";
import { UploadSession, UploadTarget } from "../../model/dto_upload_session_pb.js";
import { Attribute } from "../../model/dto_base_pb.js";
import { AssetContent_Report_UserUploaded_MPRPlane, AssetContent_Report_UserUploaded_ToothSlice } from "../../model/dto_asset_report_uploaded_pb.js";
import { Study } from "../../model/dto_study_pb.js";
import { Asset, AssetContent_Study_CBCT, AssetContent_Study_Dental_Photo, AssetContent_Study_Intraoral_XRay, AssetContent_Study_IOS_Meshes, GeneratedReportAsset } from "../../model/dto_asset_pb.js";
import { User } from "../../model/dto_user_pb.js";
import { Organization } from "../../model/dto_organization_pb.js";
import { DentalPhotoAlbum } from "../../model/dto_dental_photo_album_pb.js";
import { Report } from "../../model/dto_report_pb.js";
import { Tooth } from "../../model/dto_report_tooth_pb.js";

/**
 * @generated from message com.diagnocat.core.FailSessionReq
 */
export const FailSessionReq = proto3.makeMessageType(
  "com.diagnocat.core.FailSessionReq",
  () => [
    { no: 1, name: "UploadSessionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 100, name: "Cancelled", kind: "message", T: Empty, oneof: "Reason" },
    { no: 101, name: "Error", kind: "message", T: FailSessionReq_UploadError, oneof: "Reason" },
  ],
);

/**
 * @generated from message com.diagnocat.core.FailSessionReq.UploadError
 */
export const FailSessionReq_UploadError = proto3.makeMessageType(
  "com.diagnocat.core.FailSessionReq.UploadError",
  () => [
    { no: 1, name: "FileID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "FailSessionReq_UploadError"},
);

/**
 * @generated from message com.diagnocat.core.FailSessionResp
 */
export const FailSessionResp = proto3.makeMessageType(
  "com.diagnocat.core.FailSessionResp",
  () => [
    { no: 1, name: "UploadSession", kind: "message", T: UploadSession },
  ],
);

/**
 * @generated from message com.diagnocat.core.StartUploadSessionReq
 */
export const StartUploadSessionReq = proto3.makeMessageType(
  "com.diagnocat.core.StartUploadSessionReq",
  () => [
    { no: 1, name: "User_Avatar", kind: "message", T: StartUploadSessionReq_UserSimpleFileUpload, oneof: "Upload" },
    { no: 2, name: "User_Signature", kind: "message", T: StartUploadSessionReq_UserSimpleFileUpload, oneof: "Upload" },
    { no: 11, name: "Organization_Logo", kind: "message", T: StartUploadSessionReq_OrganizationSimpleFileUpload, oneof: "Upload" },
    { no: 12, name: "Organization_Stamp", kind: "message", T: StartUploadSessionReq_OrganizationSimpleFileUpload, oneof: "Upload" },
    { no: 13, name: "Organization_EmployeeStamp", kind: "message", T: StartUploadSessionReq_OrganizationUserSimpleFileUpload, oneof: "Upload" },
    { no: 101, name: "Study_CBCT", kind: "message", T: StartUploadSessionReq_PatientStudySimpleMultiFileUpload, oneof: "Upload" },
    { no: 102, name: "Study_Panoramic_XRay", kind: "message", T: StartUploadSessionReq_PatientStudySimpleFileUpload, oneof: "Upload" },
    { no: 103, name: "Study_Intraoral_XRay", kind: "message", T: StartUploadSessionReq_PatientStudySimpleMultiFileUpload, oneof: "Upload" },
    { no: 104, name: "Study_IOS_Meshes", kind: "message", T: StartUploadSessionReq_PatientStudySimpleMultiFileUpload, oneof: "Upload" },
    { no: 105, name: "Study_Dental_Photo", kind: "message", T: StartUploadSessionReq_PatientStudySimpleMultiFileUpload, oneof: "Upload" },
    { no: 111, name: "Patient_Document", kind: "message", T: StartUploadSessionReq_PatientSimpleMultiFileUpload, oneof: "Upload" },
    { no: 113, name: "Tooth_UserUploadedSlice", kind: "message", T: StartUploadSessionReq_ToothUserUploadedSlices, oneof: "Upload" },
    { no: 114, name: "Tooth_UserUploadedMPRPlanes", kind: "message", T: StartUploadSessionReq_ToothUserUploadedMPRPlanes, oneof: "Upload" },
    { no: 121, name: "Report_StudioApplicationStateSnapshot", kind: "message", T: StartUploadSessionReq_StudioReportFileUpload, oneof: "Upload" },
    { no: 122, name: "Report_StudioGeometry", kind: "message", T: StartUploadSessionReq_StudioReportFileUpload, oneof: "Upload" },
    { no: 1000, name: "Attributes", kind: "message", T: Attribute, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.StartUploadSessionReq.UserSimpleFileUpload
 */
export const StartUploadSessionReq_UserSimpleFileUpload = proto3.makeMessageType(
  "com.diagnocat.core.StartUploadSessionReq.UserSimpleFileUpload",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "FilePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "StartUploadSessionReq_UserSimpleFileUpload"},
);

/**
 * @generated from message com.diagnocat.core.StartUploadSessionReq.OrganizationSimpleFileUpload
 */
export const StartUploadSessionReq_OrganizationSimpleFileUpload = proto3.makeMessageType(
  "com.diagnocat.core.StartUploadSessionReq.OrganizationSimpleFileUpload",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "FilePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "StartUploadSessionReq_OrganizationSimpleFileUpload"},
);

/**
 * @generated from message com.diagnocat.core.StartUploadSessionReq.OrganizationUserSimpleFileUpload
 */
export const StartUploadSessionReq_OrganizationUserSimpleFileUpload = proto3.makeMessageType(
  "com.diagnocat.core.StartUploadSessionReq.OrganizationUserSimpleFileUpload",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "FilePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "StartUploadSessionReq_OrganizationUserSimpleFileUpload"},
);

/**
 * @generated from message com.diagnocat.core.StartUploadSessionReq.PatientStudySimpleFileUpload
 */
export const StartUploadSessionReq_PatientStudySimpleFileUpload = proto3.makeMessageType(
  "com.diagnocat.core.StartUploadSessionReq.PatientStudySimpleFileUpload",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Target" },
    { no: 2, name: "StudyID", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Target" },
    { no: 3, name: "FilePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "StartUploadSessionReq_PatientStudySimpleFileUpload"},
);

/**
 * @generated from message com.diagnocat.core.StartUploadSessionReq.PatientStudySimpleMultiFileUpload
 */
export const StartUploadSessionReq_PatientStudySimpleMultiFileUpload = proto3.makeMessageType(
  "com.diagnocat.core.StartUploadSessionReq.PatientStudySimpleMultiFileUpload",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Target" },
    { no: 2, name: "StudyID", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Target" },
    { no: 3, name: "FilePaths", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "StartUploadSessionReq_PatientStudySimpleMultiFileUpload"},
);

/**
 * @generated from message com.diagnocat.core.StartUploadSessionReq.PatientSimpleMultiFileUpload
 */
export const StartUploadSessionReq_PatientSimpleMultiFileUpload = proto3.makeMessageType(
  "com.diagnocat.core.StartUploadSessionReq.PatientSimpleMultiFileUpload",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "FilePaths", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "StartUploadSessionReq_PatientSimpleMultiFileUpload"},
);

/**
 * @generated from message com.diagnocat.core.StartUploadSessionReq.StudioReportFileUpload
 */
export const StartUploadSessionReq_StudioReportFileUpload = proto3.makeMessageType(
  "com.diagnocat.core.StartUploadSessionReq.StudioReportFileUpload",
  () => [
    { no: 1, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "StartUploadSessionReq_StudioReportFileUpload"},
);

/**
 * @generated from message com.diagnocat.core.StartUploadSessionReq.ToothUserUploadedSlices
 */
export const StartUploadSessionReq_ToothUserUploadedSlices = proto3.makeMessageType(
  "com.diagnocat.core.StartUploadSessionReq.ToothUserUploadedSlices",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Slices", kind: "message", T: AssetContent_Report_UserUploaded_ToothSlice, repeated: true },
  ],
  {localName: "StartUploadSessionReq_ToothUserUploadedSlices"},
);

/**
 * @generated from message com.diagnocat.core.StartUploadSessionReq.ToothUserUploadedMPRPlanes
 */
export const StartUploadSessionReq_ToothUserUploadedMPRPlanes = proto3.makeMessageType(
  "com.diagnocat.core.StartUploadSessionReq.ToothUserUploadedMPRPlanes",
  () => [
    { no: 1, name: "ToothID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "MPRPanes", kind: "message", T: AssetContent_Report_UserUploaded_MPRPlane, repeated: true },
  ],
  {localName: "StartUploadSessionReq_ToothUserUploadedMPRPlanes"},
);

/**
 * @generated from message com.diagnocat.core.StartUploadSessionResp
 */
export const StartUploadSessionResp = proto3.makeMessageType(
  "com.diagnocat.core.StartUploadSessionResp",
  () => [
    { no: 1, name: "UploadSession", kind: "message", T: UploadSession },
    { no: 2, name: "UploadTargets", kind: "message", T: UploadTarget, repeated: true },
    { no: 3, name: "Study", kind: "message", T: Study },
  ],
);

/**
 * @generated from message com.diagnocat.core.StartPipelinesUploadSessionReq
 */
export const StartPipelinesUploadSessionReq = proto3.makeMessageType(
  "com.diagnocat.core.StartPipelinesUploadSessionReq",
  () => [
    { no: 1, name: "AssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "PipelinesRunID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "GeneratedReport", kind: "message", T: GeneratedReportAsset },
  ],
);

/**
 * only if UploadSession is not closed yet
 *
 * @generated from message com.diagnocat.core.AddFilesToUploadSessionReq
 */
export const AddFilesToUploadSessionReq = proto3.makeMessageType(
  "com.diagnocat.core.AddFilesToUploadSessionReq",
  () => [
    { no: 1, name: "UploadSessionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "CBCT", kind: "message", T: AssetContent_Study_CBCT, oneof: "Content" },
    { no: 102, name: "Intraoral_XRay", kind: "message", T: AssetContent_Study_Intraoral_XRay, oneof: "Content" },
    { no: 103, name: "IOS_Meshes", kind: "message", T: AssetContent_Study_IOS_Meshes, oneof: "Content" },
    { no: 104, name: "Dental_Photo", kind: "message", T: AssetContent_Study_Dental_Photo, oneof: "Content" },
  ],
);

/**
 * @generated from message com.diagnocat.core.AddFilesToUploadSessionResp
 */
export const AddFilesToUploadSessionResp = proto3.makeMessageType(
  "com.diagnocat.core.AddFilesToUploadSessionResp",
  () => [
    { no: 1, name: "UploadSession", kind: "message", T: UploadSession },
    { no: 2, name: "UploadTargets", kind: "message", T: UploadTarget, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.NotifyFileUploadStartedReq
 */
export const NotifyFileUploadStartedReq = proto3.makeMessageType(
  "com.diagnocat.core.NotifyFileUploadStartedReq",
  () => [
    { no: 1, name: "FileID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.NotifyFileUploadStartedResp
 */
export const NotifyFileUploadStartedResp = proto3.makeMessageType(
  "com.diagnocat.core.NotifyFileUploadStartedResp",
  () => [
    { no: 1, name: "UploadSession", kind: "message", T: UploadSession },
  ],
);

/**
 * @generated from message com.diagnocat.core.NotifyFileUploadProgressReq
 */
export const NotifyFileUploadProgressReq = proto3.makeMessageType(
  "com.diagnocat.core.NotifyFileUploadProgressReq",
  () => [
    { no: 1, name: "FileID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.NotifyFileUploadProgressResp
 */
export const NotifyFileUploadProgressResp = proto3.makeMessageType(
  "com.diagnocat.core.NotifyFileUploadProgressResp",
  () => [
    { no: 1, name: "UploadSession", kind: "message", T: UploadSession },
  ],
);

/**
 * @generated from message com.diagnocat.core.NotifyFileUploadFinishedReq
 */
export const NotifyFileUploadFinishedReq = proto3.makeMessageType(
  "com.diagnocat.core.NotifyFileUploadFinishedReq",
  () => [
    { no: 1, name: "FileID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.NotifyFileUploadFinishedResp
 */
export const NotifyFileUploadFinishedResp = proto3.makeMessageType(
  "com.diagnocat.core.NotifyFileUploadFinishedResp",
  () => [
    { no: 1, name: "UploadSession", kind: "message", T: UploadSession },
  ],
);

/**
 * @generated from message com.diagnocat.core.NotifyFileUploadFinishedResp_Upcoming
 */
export const NotifyFileUploadFinishedResp_Upcoming = proto3.makeMessageType(
  "com.diagnocat.core.NotifyFileUploadFinishedResp_Upcoming",
  () => [
    { no: 1, name: "UploadSession", kind: "message", T: UploadSession, oneof: "Result" },
    { no: 2, name: "Ping", kind: "message", T: Empty, oneof: "Result" },
  ],
);

/**
 * @generated from message com.diagnocat.core.CloseSessionReq
 */
export const CloseSessionReq = proto3.makeMessageType(
  "com.diagnocat.core.CloseSessionReq",
  () => [
    { no: 1, name: "UploadSessionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.CloseSessionResp
 */
export const CloseSessionResp = proto3.makeMessageType(
  "com.diagnocat.core.CloseSessionResp",
  () => [
    { no: 1, name: "Assets", kind: "message", T: Asset, repeated: true },
    { no: 2, name: "User", kind: "message", T: User },
    { no: 3, name: "Organization", kind: "message", T: Organization },
    { no: 4, name: "Study", kind: "message", T: Study },
    { no: 7, name: "Studies", kind: "message", T: Study, repeated: true },
    { no: 8, name: "DentalPhotoAlbums", kind: "message", T: DentalPhotoAlbum, repeated: true },
    { no: 5, name: "Reports", kind: "message", T: Report, repeated: true },
    { no: 6, name: "Tooth", kind: "message", T: Tooth },
  ],
);

