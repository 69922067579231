import { ToothWithLocalization } from '../config/tooth.type';

/**
 * @deprecated
 */
export const isDuplicate = (
  teeth: ToothWithLocalization[],
  tooth: ToothWithLocalization,
) =>
  teeth.some(
    (item) =>
      item.ISONumber === tooth?.ISONumber &&
      item.SupernumeraryIndex === tooth.SupernumeraryIndex &&
      item?.Localization.ID !== tooth?.Localization.ID,
  );
