import { AlbumService } from '@/shared/api/protocol-ts/api/core/svc_album_connectweb.ts';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import {
  DeleteAlbumReq,
  DeleteAlbumResp,
  DeleteStudiesFromAlbumReq,
  DeleteStudiesFromAlbumResp,
  SetMainInfoReq,
  SetMainInfoResp,
} from '@/shared/api/protocol-ts/api/core/svc_album_pb';
import api from '@/shared/api/api.ts';

type AlbumRequests = keyof typeof AlbumService.methods;

const generateAlbumThunk = createThunkGenerator<AlbumRequests>(SliceName.album);

export const deleteStudiesFromAlbum = generateAlbumThunk<
  DeleteStudiesFromAlbumReq,
  DeleteStudiesFromAlbumResp
>('deleteStudiesFromAlbum', api.album.deleteStudiesFromAlbum);

export const editAlbum = generateAlbumThunk<SetMainInfoReq, SetMainInfoResp>(
  'setMainInfo',
  api.album.setMainInfo,
);

export const deleteAlbum = generateAlbumThunk<DeleteAlbumReq, DeleteAlbumResp>(
  'deleteAlbum',
  api.album.deleteAlbum,
);
