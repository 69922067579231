import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/model/store';

import { albumAdapter } from './albumSlice.ts';

export const { selectAll, selectById } = albumAdapter.getSelectors(
  (state: RootState) => state.album,
);

export const selectAllPhotos = createSelector(selectAll, (albums) =>
  albums.flatMap((album) => album.StudyAssetIDs),
);
