import React, { FC, ReactNode, Suspense } from 'react';
import cn from 'classnames';
import 'react-quill/dist/quill.snow.css';

import { Spinner } from '@/shared/ui';

import { formats, modules } from '../../config';

import styles from './Editor.module.scss';

const ReactQuill = React.lazy(() => import('react-quill'));

type EditorProps = {
  value: string;
  setValue: (newValue: string) => void;
  placeholder: ReactNode;
  className?: string;
};

export const Editor: FC<EditorProps> = (props) => {
  const { value, setValue, placeholder, className } = props;

  return (
    <div className={cn(styles.container, className)}>
      <Suspense fallback={<Spinner />}>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          placeholder={placeholder as string}
          modules={modules}
          formats={formats}
        />
      </Suspense>
    </div>
  );
};
