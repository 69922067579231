import { capitalize } from 'lodash-es';
import { head, pipe, split } from 'ramda';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';

import { i18n, reportsModel } from '@/entities/reports';

export const useGetReportConfigs = (opts: {
  reportID: string;
  patientID: string;
  isWide: boolean;
}) => {
  const { reportID, isWide, patientID } = opts;

  const { formatMessage, formatDate } = useIntl();

  const { reportType, reportCreatedDate, reportPath } = useAppSelector(
    reportsModel.selectors.selectReportBreadcrumbData({ reportID, patientID }),
  );

  const reportDate = formatDate(reportCreatedDate?.toDate() ?? new Date(), {
    dateStyle: 'medium',
  });

  const shortReportDate = `${formatDate(
    reportCreatedDate?.toDate() ?? new Date(),
    {
      day: 'numeric',
    },
  )}, 
  ${formatDate(reportCreatedDate?.toDate() ?? new Date(), {
    year: 'numeric',
  })}`;

  const reportLabel = reportType
    ? `${formatMessage(i18n[reportType])} ${capitalize(reportDate)}`
    : '';

  const shortReportLabel = reportLabel
    ? `${pipe(split(' '), head)(reportLabel)}... ${shortReportDate}`
    : '';

  return {
    reportLabel: isWide ? reportLabel : shortReportLabel,
    reportPath,
  };
};
