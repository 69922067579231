import { FC } from 'react';
import { useIntl } from 'react-intl/lib';

import sadCatImg from '@/shared/assets/images/sad-cat.png';
import { Result } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import { billingModel } from '@/entities/billing';

import styles from './NoSubscription.module.scss';

type NoSubscriptionProps = {
  className?: string;
  text?: string;
};

export const NoSubscription: FC<NoSubscriptionProps> = (props) => {
  const { className, text } = props;

  const { formatMessage, formatDate } = useIntl();

  const unpaidOrder = useAppSelector(billingModel.selectors.selectUnPaidOrder);

  const dueDate =
    unpaidOrder?.Kind?.case === 'Billed' &&
    new Date(Number(unpaidOrder.Kind.value.DueDate));

  const emptyResultText =
    unpaidOrder && dueDate
      ? formatMessage(
          {
            id: 'billing.paymentReminder.waitingPayment',
            defaultMessage:
              'Your subscription has expired. Please pay the invoice by {dueDate} to renew it.',
          },
          {
            dueDate: formatDate(dueDate as Date, {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }),
          },
        )
      : formatMessage({
          id: 'subscription.currentSubscription.noSubscription',
          defaultMessage: 'No purchased plans yet',
        });

  return (
    <Result
      className={className}
      textClassName={styles.text}
      icon={
        <img src={sadCatImg} width={130} height={130} alt={emptyResultText} />
      }
      text={text ?? emptyResultText}
    />
  );
};
