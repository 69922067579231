import { FC, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConnectError } from '@bufbuild/connect';

import { DatePicker, Input, Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { ModalID, modalModel } from '@/entities/modal';
import { userModel } from '@/entities/user';
import { albumModel } from '@/entities/album';

import { errorToastCaller } from '@/features/errorToastCaller';

import {
  EditAlbumFormPayload,
  editAlbumFormSchema,
} from '../config/formSchema';

import styles from './EditAlbumModal.module.scss';

type EditAlbumModalProps = {
  className?: string;
  testID?: string;
};

export const EditAlbumModal: FC<EditAlbumModalProps> = (props) => {
  const { className, testID } = props;
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const locale = useAppSelector(userModel.selectors.selectUserLocale);

  const { visible, data } = useAppSelector(
    modalModel.selectors.selectEditAlbumModal,
  );

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
    setValue,
  } = useForm<EditAlbumFormPayload>({
    resolver: yupResolver(editAlbumFormSchema),
  });

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.EditAlbumModal));
    reset();
  }, [dispatch, reset]);

  const onSubmit: SubmitHandler<EditAlbumFormPayload> = useCallback(
    async (formData) => {
      try {
        const { DentalPhotoAlbum } = await dispatch(
          albumModel.thunks.editAlbum({
            AlbumID: data.albumID,
            Name: formData.albumName,
            Time: {
              // @ts-expect-error WARN: if you send Timestamp back fail
              seconds: Math.round(formData.albumTime.getTime() / 1000),
            },
          }),
        ).unwrap();

        if (DentalPhotoAlbum) {
          dispatch(albumModel.actions.setNewestOne(DentalPhotoAlbum));
        }

        handleClose();
      } catch (error) {
        errorToastCaller(error as ConnectError);
      }
    },
    [data.albumID, dispatch, handleClose],
  );

  useEffect(() => {
    if (data.albumID) {
      setValue('albumName', data.albumName);
      setValue('albumTime', data?.time ? new Date(data.time) : new Date());
    }

    return () => reset();
  }, [data.albumID, data.albumName, data.time, reset, setValue]);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      isOpen={visible}
      data-testid={testID}
      className={cn(styles.container, className)}
      title={formatMessage({
        id: 'editAlbumModal.title',
        defaultMessage: 'Edit album',
      })}
      onCancel={handleClose}
      okButtonProps={{
        type: 'submit',
        form: 'editAlbumModal',
        loading: isSubmitting,
      }}
      okButtonText={formatMessage({
        id: 'global.save',
        defaultMessage: 'Save',
      })}
      onOk={handleSubmit(onSubmit)}
    >
      <form id="editAlbumModal" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.row}>
          <Controller
            control={control}
            name="albumName"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                type="text"
                label={formatMessage({
                  id: 'global.albumName',
                  defaultMessage: 'Album name',
                })}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="albumTime"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DatePicker
                locale={locale}
                value={value}
                maxDate={new Date()}
                onChange={onChange}
                placeholder={formatMessage({
                  id: 'album.edit.placeholder.selectDate',
                  defaultMessage: 'Select date',
                })}
                label={
                  <FormattedMessage
                    id="album.edit.label.date"
                    defaultMessage="Album date"
                  />
                }
                error={error?.message}
              />
            )}
          />
        </div>
      </form>
    </Modal>
  );
};
