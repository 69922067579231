import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Layout, Result } from '@/shared/ui';
import sadCatImg from '@/shared/assets/images/sad-cat.png';

import { Header } from '@/widgets/Header';

import styles from './DentalPhotos.module.scss';

export const DentalPhotos: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main>
          <div className={styles.container}>
            <h2 className="h3">
              {formatMessage({
                id: 'dentalPhotosPage.title',
                defaultMessage: 'Dental photos',
              })}
            </h2>

            <div className={styles.emptyResult}>
              <Result
                icon={
                  <img
                    src={sadCatImg}
                    width={130}
                    height={130}
                    alt={formatMessage({
                      id: 'imgAltText.sadCat',
                      defaultMessage: 'Sad cat',
                    })}
                  />
                }
                text={formatMessage({
                  id: 'dentalPhotosPage.emptyResult.text',
                  defaultMessage: 'There are no dental photos yet',
                })}
              />
            </div>
          </div>
        </Layout.Main>
      </Layout.Content>
    </Layout>
  );
};
