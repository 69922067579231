import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { ConnectError } from '@bufbuild/connect';

import { ErrorText, Modal, ModalProps } from '@/shared/ui';

type RemoveModalProps = {
  message: string;
  title: string;
  studyID?: string;
  className?: string;
  testID?: string;
  onRemoveCallback: () => void;
} & Pick<ModalProps, 'isOpen' | 'onCancel'>;

export const RemoveModal: FC<RemoveModalProps> = (props) => {
  const {
    className,
    testID,
    message,
    title,
    isOpen,
    onCancel: onClose,
    onRemoveCallback,
  } = props;
  const { formatMessage } = useIntl();

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleClose = () => {
    setErrorMessage(undefined);
    if (onClose) {
      onClose();
    }
  };

  const handleRemoveStudy = async () => {
    setLoading(true);

    try {
      onRemoveCallback();
    } catch (error) {
      setErrorMessage((error as ConnectError)?.rawMessage);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      className={className}
      data-testid={testID}
      title={title}
      isOpen={isOpen}
      bodyClassName={'p1'}
      onCancel={handleClose}
      onOk={handleRemoveStudy}
      okButtonText={formatMessage({
        id: 'global.remove',
        defaultMessage: 'Remove',
      })}
      okButtonProps={{
        danger: true,
        loading: isLoading,
      }}
    >
      {message}
      <ErrorText error={errorMessage} />
    </Modal>
  );
};
