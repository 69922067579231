import { useIntl } from 'react-intl';

import { Invitation_InvitationStatus } from '@/shared/api/protocol-ts/model/dto_access_pb';
import { useAppSelector } from '@/shared/hooks';
import { getName } from '@/shared/lib';

import { userModel } from '@/entities/user';

import { getAvatarSrcByUserID } from '../../assets';
import { accessModel } from '../../access';
import { getOrganizationDoctorsRoles } from '../lib/getOrganizationDoctorsRoles';
import { getOrganizationDoctorSpecializations } from '../lib/getOrganizationDoctorSpecializations';
import { DoctorInterface } from '../config';
import * as organizationModel from '../model';

// TODO: Need to discuss invite entity with the backend team.
// useOrganizationSettingsStream: HistoricalInvitation and UpdatedInvitation
// There is a lot of commented code because of that.
export const useGetOrganizationDoctorsInterfaces = (): DoctorInterface[] => {
  const allOrganizationDoctors = useAppSelector(
    organizationModel.selectors.selectAllUsers,
  );

  const organizationDoctorInvitations = useAppSelector(
    accessModel.selectors.selectOrganizationDoctorInvitations,
  );

  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const { formatMessage } = useIntl();

  const invitedDoctors: DoctorInterface[] =
    organizationDoctorInvitations.reduce((doctors, invite) => {
      if (invite.Status === Invitation_InvitationStatus.StatusPending) {
        const doctor =
          invite.Target?.Target.case === 'Organization'
            ? invite.Target?.Target.value
            : undefined;
        const doctorName = getName(doctor?.FirstName, doctor?.LastName, {
          userLocale,
        });
        const doctorInitials = getName(doctor?.FirstName, doctor?.LastName, {
          userLocale,
          shortFirstName: true,
          shortLastName: true,
        });

        const invitedDoctor = {
          id: invite.ID,
          avatarSrc: '',
          fullName: doctorName,
          specializations: doctor?.JobPositions
            ? getOrganizationDoctorSpecializations(
                doctor?.JobPositions,
                formatMessage,
              )
            : [],
          roles: doctor?.Roles
            ? getOrganizationDoctorsRoles(doctor?.Roles, formatMessage)
            : [],
          initials: doctorInitials,
          email: invite.Recipient?.Email ?? '',
          doctorInfo: {
            ...doctor,
            InvitationID: invite.ID,
            email: invite.Recipient?.Email ?? '',
          },
        };

        doctors.push(invitedDoctor as DoctorInterface);
      }

      return doctors;
    }, [] as DoctorInterface[]);

  const orgDoctors: DoctorInterface[] = allOrganizationDoctors?.length
    ? allOrganizationDoctors?.map((doctor) => {
        // TODO: Need to update the avatar src
        const avatarSrc = getAvatarSrcByUserID(doctor.UserID, 'thumbnail');
        const doctorName = getName(
          doctor.PersonalData?.FirstName,
          doctor.PersonalData?.LastName,
          {
            userLocale,
          },
        );
        const doctorInitials = getName(
          doctor.PersonalData?.FirstName,
          doctor.PersonalData?.LastName,
          {
            userLocale,
            shortFirstName: true,
            shortLastName: true,
          },
        );

        return {
          id: doctor.UserID,
          avatarSrc,
          fullName: doctorName,
          email: doctor?.PersonalData?.Email ?? '',
          specializations: getOrganizationDoctorSpecializations(
            doctor.JobPositions,
            formatMessage,
          ),
          roles: getOrganizationDoctorsRoles(doctor.Roles, formatMessage),
          doctorInfo: doctor,
          initials: doctorInitials,
        };
      })
    : [];

  return [...invitedDoctors, ...orgDoctors];
};
