import { useCallback, useEffect } from 'react';

import { userModel } from '@/entities/user';

import api from '../api/api';

import { useAppSelector } from './store';

export const useHubspotChat = () => {
  const user = useAppSelector(userModel.selectors.selectCurrentUser);
  const email = user.PersonalData?.Emails[0];

  const disableHubspot = localStorage.getItem('disableHubspot') === 'true';

  const restartHubspot = useCallback(() => {
    window.HubSpotConversations?.clear({ resetWidget: true });
    window.HubSpotConversations?.widget.load();
  }, []);

  const onConversationsAPIReady = useCallback(async () => {
    // For authenticated users
    if (email) {
      const { Token } = await api.hubspot.getChatIdentificationToken({});

      window.hsConversationsSettings = {
        loadImmediately: false,
        identificationEmail: email,
        identificationToken: Token,
      };

      if (Token && window.HubSpotConversations) {
        restartHubspot();
      } else {
        window.hsConversationsOnReady = [restartHubspot];
      }
    }
    // For anonymous users
    else {
      window.hsConversationsSettings = {
        loadImmediately: true,
      };

      if (window.HubSpotConversations) {
        restartHubspot();
      } else {
        window.hsConversationsOnReady = [restartHubspot];
      }
    }
  }, [email, restartHubspot]);

  useEffect(() => {
    if (!disableHubspot) {
      onConversationsAPIReady();
    }
  }, [email, onConversationsAPIReady, disableHubspot]);
};
