import { useIntl } from 'react-intl';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Button, ButtonSize } from '@/shared/ui';

import { toothModel } from '@/entities/tooth';
import { useCheckReportSignature } from '@/entities/reports';

type ToothChartCustomModeToggleProps = {
  className?: string;
  buttonSize?: ButtonSize;
};

export const ToothChartCustomModeToggle = (
  props: ToothChartCustomModeToggleProps,
) => {
  const { className, buttonSize = 'small' } = props;

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const customMode = useAppSelector(
    toothModel.selectors.selectToothChartCustomMode,
  );
  const updatingROI = useAppSelector(toothModel.selectors.selectUpdatingROI);

  const { checkReportSignature } = useCheckReportSignature();

  const toggleCustomMode = () => {
    if (customMode) {
      checkReportSignature({
        onSignatureChecked: async () => {
          dispatch(toothModel.thunks.updateCurrentReportROI()).then(() => {
            dispatch(toothModel.actions.setToothChartCustomMode(!customMode));
          });
        },
      });
      return;
    }

    dispatch(toothModel.actions.setToothChartCustomMode(!customMode));
  };

  const buttonText = customMode
    ? formatMessage({
        id: 'toothChart.customModeEnabled',
        defaultMessage: 'Save',
      })
    : formatMessage({
        id: 'toothChart.customModeDisabled',
        defaultMessage: 'Settings',
      });

  useEffect(
    () => () => {
      dispatch(toothModel.actions.setToothChartCustomMode(false));
    },
    [],
  );

  return (
    <Button
      className={className}
      variant="secondary"
      size={buttonSize}
      loading={updatingROI}
      onClick={toggleCustomMode}
    >
      {buttonText}
    </Button>
  );
};
