// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_dental_photo_album.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Created, Deleted, Revision } from "./dto_base_pb.js";

/**
 * @generated from message com.diagnocat.model.DentalPhotoAlbum
 */
export const DentalPhotoAlbum = proto3.makeMessageType(
  "com.diagnocat.model.DentalPhotoAlbum",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "Time", kind: "message", T: Timestamp },
    { no: 4, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "StudyIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "StudyAssetIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
    { no: 10050, name: "Deleted", kind: "message", T: Deleted },
  ],
);

