import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { toothTypeMessages } from '../../config/i18n';
import { ToothType } from '../../config/tooth.type';
import healthyIcon from '../../../../shared/assets/images/ToothStatusIcon/healthyIconColored.svg';
import unhealthyIcon from '../../../../shared/assets/images/ToothStatusIcon/unhealthyIconColored.svg';
import lowProbabilityIcon from '../../../../shared/assets/images/ToothStatusIcon/lowProbabilityIconColored.svg';
import missingIcon from '../../../../shared/assets/images/ToothStatusIcon/missingIconColored.svg';
import treatedIcon from '../../../../shared/assets/images/ToothStatusIcon/treatedIconColored.svg';
import healthyIconBnW from '../../../../shared/assets/images/ToothStatusIcon/healthyIconB&W.svg';
import unhealthyIconBnW from '../../../../shared/assets/images/ToothStatusIcon/unhealthyIconB&W.svg';
import lowProbabilityIconBnW from '../../../../shared/assets/images/ToothStatusIcon/lowProbabilityIconB&W.svg';
import missingIconBnW from '../../../../shared/assets/images/ToothStatusIcon/missingIconB&W.svg';
import treatedIconBnW from '../../../../shared/assets/images/ToothStatusIcon/treatedIconB&W.svg';

import styles from './ToothChartLegends.module.scss';

type ToothChartLegends = Exclude<ToothType, 'all'>[];

const icons: Record<
  'colored' | 'BnW',
  Record<Exclude<ToothType, 'all'>, string>
> = {
  colored: {
    healthy: healthyIcon,
    unhealthy: unhealthyIcon,
    missing: missingIcon,
    treated: treatedIcon,
    lowProbability: lowProbabilityIcon,
  },
  BnW: {
    healthy: healthyIconBnW,
    unhealthy: unhealthyIconBnW,
    missing: missingIconBnW,
    treated: treatedIconBnW,
    lowProbability: lowProbabilityIconBnW,
  },
};

const DEFAULT_TOOTH_CHART_LEGENDS: ToothChartLegends = [
  'unhealthy',
  'treated',
  'healthy',
  'missing',
];

type ToothChartLegendsProps = {
  className?: string;
  toothChartLegends?: ToothChartLegends;
  blackAndWhiteMode?: boolean;
  fragmented?: boolean;
};

export const ToothChartLegends: FC<ToothChartLegendsProps> = (props) => {
  const {
    toothChartLegends = DEFAULT_TOOTH_CHART_LEGENDS,
    className,
    blackAndWhiteMode = false,
    fragmented = false,
  } = props;

  const { formatMessage } = useIntl();

  return fragmented ? (
    toothChartLegends.map((legend) => (
      <div className={cn(styles.legend)} key={legend}>
        <span className={cn(styles.legendTitle, 'p3t')}>
          <img
            width={16}
            height={16}
            src={blackAndWhiteMode ? icons.BnW[legend] : icons.colored[legend]}
            alt={`${legend}`}
          />
          <span>{formatMessage(toothTypeMessages[legend])}</span>
        </span>
      </div>
    ))
  ) : (
    <ul className={cn(styles.container, className)}>
      {toothChartLegends.map((legend) => (
        <li className={cn(styles.legend)} key={legend}>
          <span className={cn(styles.legendTitle, 'p3t')}>
            <img
              width={16}
              height={16}
              src={
                blackAndWhiteMode ? icons.BnW[legend] : icons.colored[legend]
              }
              alt={`${legend}`}
            />
            <span>{formatMessage(toothTypeMessages[legend])}</span>
          </span>
        </li>
      ))}
    </ul>
  );
};
