import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormattedDate, FormattedNumber } from 'react-intl/lib';

import { Button, Description } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { shouldAskAdditionalInfo } from '@/shared/lib';
import {
  Order,
  Order_BilledOrderKind,
} from '@/shared/api/protocol-ts/api/billing_new/dto_order_new_pb';
import { PayOrderReq } from '@/shared/api/protocol-ts/api/billing_new/svc_billing_new_pb';
import { BILLING_ZONE_WITHOUT_STRIPE_REGEXP } from '@/shared/config';

import { billingModel } from '@/entities/billing';
import { ModalID, modalModel } from '@/entities/modal';

import { InvoiceStatuses } from '../../config/type';
import { invoiceStatusDefaultMessages } from '../../config/i18n';
import { getInvoiceStatus } from '../../lib/getInvoiceStatus';
import { useProductName } from '../../hooks/useProductName.ts';

import styles from './Invoice.module.scss';

type InvoiceRowProps = {
  invoice: Order;
  className?: string;
  testID?: string;
};

const invoiceStatusesBGStyles = {
  new: styles.newInvoice,
  overdue: styles.overdueInvoice,
} as Record<InvoiceStatuses, string>;

const invoiceStatusesTextStyles = {
  paid: styles.paidInvoiceText,
  overdue: styles.overdueInvoiceText,
} as Record<InvoiceStatuses, string>;

export const Invoice: FC<InvoiceRowProps> = (props) => {
  const { className, testID, invoice } = props;

  const { ID, Kind, Effect, Created } = invoice;

  const productName = useProductName(Effect?.Effect);

  const billed = Kind.value as Order_BilledOrderKind;

  const paid = billed?.Paid ?? false;
  const overdue = billed?.Overdue ?? false;
  const amount = billed?.Amount ?? '0';
  const invoicePDFURL = billed?.InvoicePDFURL ?? '';
  const currency = billed?.Currency ?? 'USD';

  const { formatMessage, formatNumber } = useIntl();

  const { BillingZones, BillingInformation } = useAppSelector(
    billingModel.selectors.selectAccount,
  );

  const dispatch = useAppDispatch();

  const billingZone = BillingZones?.at(0);

  const invoiceStatus = getInvoiceStatus(paid, overdue);

  const isWithStripeBillingZone = !BILLING_ZONE_WITHOUT_STRIPE_REGEXP.test(
    billingZone ?? '',
  );

  const shouldAskBillingInfo = useMemo(
    () => shouldAskAdditionalInfo(billingZone, BillingInformation),
    [billingZone, BillingInformation],
  );

  const handlePayClick = useCallback(async () => {
    if (shouldAskBillingInfo) {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.BillingInformationModal,
          data: {
            isEdit: false,
            invoiceID: ID,
          },
        }),
      );
    } else {
      const { Reaction } = await dispatch(
        billingModel.thunks.payOrder({
          OrderID: ID,
        } as PayOrderReq),
      ).unwrap();

      if (Reaction.case === 'URL') {
        window.open(Reaction.value, '_blank');
      }
    }
  }, [ID, shouldAskBillingInfo]);

  return (
    <div
      data-testid={testID}
      className={cn(
        styles.container,
        className,
        invoiceStatus in invoiceStatusesBGStyles &&
          invoiceStatusesBGStyles[invoiceStatus],
      )}
    >
      <Description
        isVertical
        contentClassName="p2"
        className={styles.date}
        label={formatMessage({
          id: 'global.date',
          defaultMessage: 'Date',
        })}
      >
        {Created?.At && <FormattedDate value={Created?.At?.toDate()} />}
      </Description>

      <Description
        isVertical
        className={styles.price}
        contentClassName="p2"
        label={formatMessage({
          id: 'global.price',
          defaultMessage: 'Price',
        })}
      >
        <FormattedNumber
          maximumFractionDigits={0}
          value={parseInt(amount, 10)}
          style="currency"
          currencyDisplay="narrowSymbol"
          currency={currency}
        />
      </Description>

      <Description
        isVertical
        className={styles.status}
        contentClassName={cn(
          'p2',
          invoiceStatus in invoiceStatusesTextStyles &&
            invoiceStatusesTextStyles[invoiceStatus],
        )}
        label={formatMessage({
          id: 'global.status',
          defaultMessage: 'Status',
        })}
      >
        {formatMessage(invoiceStatusDefaultMessages[invoiceStatus])}
      </Description>

      <Description
        isVertical
        className={styles.name}
        contentClassName="p2"
        label={formatMessage({
          id: 'invoices.invoiceRow.productName',
          defaultMessage: 'Product name',
        })}
      >
        {productName}
      </Description>

      {paid && invoicePDFURL ? (
        <a
          href={invoicePDFURL}
          target="_blank"
          rel="noreferrer"
          className={cn(styles.downloadLink, 'p2')}
        >
          <FormattedMessage id="global.download" defaultMessage="Download" />
        </a>
      ) : (
        isWithStripeBillingZone &&
        Number(amount) > 0 && (
          <Button
            size="small"
            className={styles.payButton}
            style={{ minWidth: '140px' }}
            onClick={handlePayClick}
          >
            <FormattedMessage
              id="invoices.invoiceRow.payButton"
              defaultMessage="Pay {value}"
              values={{
                value: formatNumber(parseInt(amount, 10), {
                  maximumFractionDigits: 0,
                  style: 'currency',
                  currencyDisplay: 'narrowSymbol',
                  currency,
                }),
              }}
            />
          </Button>
        )
      )}
    </div>
  );
};
