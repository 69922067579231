import { BASE_URI } from '@/shared/config';

import { ImageSizeType } from '../config/types';

/** @deprecated */
export const getAvatarSrcByUserID = (
  userID: string,
  imageSize: ImageSizeType,
): string =>
  `${BASE_URI}/api/storage/download/user_avatar/${imageSize}/${userID}`;
