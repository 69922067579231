import { differenceInMonths, differenceInYears } from 'date-fns';
import { useIntl } from 'react-intl';

import { useUserTimezone } from '@/shared/hooks';

import {
  SHOW_AGE_IN_MONTHS_THRESHOLD,
  ADULT_AGE,
  MONTHS_IN_YEAR,
  ONE_YEAR,
} from '../config';

type Options = {
  patientDateOfBirth: string | undefined;
  onlyAge?: boolean;
  pluralized?: boolean;
};

export const useFormatPatientDateOfBirth = (options: Options) => {
  const { patientDateOfBirth, pluralized = false, onlyAge = false } = options;

  const { formatMessage, formatDate } = useIntl();

  const userTimezone = useUserTimezone();

  if (!patientDateOfBirth) {
    return '';
  }

  const dateOfBirth = formatDate(new Date(patientDateOfBirth), {
    dateStyle: 'long',
    timeZone: userTimezone,
  });

  const ageInYears = differenceInYears(
    new Date(),
    new Date(patientDateOfBirth),
  );

  const isChild = ageInYears <= SHOW_AGE_IN_MONTHS_THRESHOLD;

  const ageInMonths = differenceInMonths(
    new Date(),
    new Date(patientDateOfBirth),
  );

  const pluralizedMonth = formatMessage(
    {
      id: 'global.ageInMonth',
      defaultMessage: '{age, plural, one {# month} other {# months}}',
    },
    { age: ageInMonths },
  );

  const age = isChild
    ? pluralizedMonth
    : formatMessage(
        {
          id: 'global.age',
          defaultMessage: '{age, plural, one {# y.o.} other {# y.o.}}',
        },
        { age: ageInYears },
      );

  if (pluralized) {
    const formattedAgeInYears = formatMessage(
      {
        id: 'global.ageInYear',
        defaultMessage: '{age, plural, one {# year} other {# years}}',
      },
      { age: ageInYears },
    );

    if (ageInYears >= ADULT_AGE) {
      return formattedAgeInYears;
    }

    if (ageInYears >= ONE_YEAR) {
      const remainingMonths = ageInMonths - ageInYears * MONTHS_IN_YEAR;

      const formattedMonths = remainingMonths
        ? formatMessage(
            {
              id: 'global.ageInMonth',
              defaultMessage: '{age, plural, one {# month} other {# months}}',
            },
            { age: remainingMonths },
          )
        : '';

      const comma = remainingMonths ? ',' : '';

      return `${formattedAgeInYears}${comma} ${formattedMonths}`;
    }

    return pluralizedMonth;
  }

  return onlyAge ? age : `${dateOfBirth}, ${age}`;
};
