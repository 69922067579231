import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Icon } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import { billingModel } from '@/entities/billing';

import styles from './PaymentReminder.module.scss';

type PaymentReminderProps = {
  className?: string;
  testID?: string;
};

export const PaymentReminder: FC<PaymentReminderProps> = (props) => {
  const { className, testID } = props;

  const { formatMessage, formatDate } = useIntl();

  const currentActiveSubscription = useAppSelector(
    billingModel.selectors.selectCurrentSubscription,
  );
  const activeSubscriptions = useAppSelector(
    billingModel.selectors.selectActiveSubscriptions,
  );
  const unpaidOrder = useAppSelector(billingModel.selectors.selectUnPaidOrder);

  if (activeSubscriptions.length < 2 && !unpaidOrder) {
    return null;
  }

  return (
    <div
      data-testid={testID}
      className={cn(styles.container, className, unpaidOrder && styles.warning)}
    >
      <Icon name={unpaidOrder ? 'attention' : 'check'} size={24} />

      <span className="p3">
        {unpaidOrder && activeSubscriptions.length === 1
          ? formatMessage(
              {
                id: 'billing.paymentReminder.waitingPayment',
                defaultMessage:
                  'Payment pending for next subscription period. Subscription active until {dueDate}',
              },
              {
                dueDate: formatDate(currentActiveSubscription?.End?.toDate(), {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }),
              },
            )
          : formatMessage(
              {
                id: 'billing.paymentReminder.paid',
                defaultMessage:
                  "You've successfully paid for the next subscription period starting on {startDate}",
              },
              {
                startDate: formatDate(
                  activeSubscriptions?.at(-1)?.Start?.toDate(),
                  {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  },
                ),
              },
            )}
      </span>
    </div>
  );
};
