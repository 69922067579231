import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { ProductState } from '@/shared/api/protocol-ts/api/billing_new/dto_product_new_pb';

import { billingModel } from '@/entities/billing';
import { organizationModel } from '@/entities/organization';

import { BillingPeriod } from '../BillingPeriod/BillingPeriod.tsx';
import { NoSubscription } from '../NoSubscription/NoSubscription';
import { TopUpLimitsModal } from '../TopUpLimitsModal/TopUpLimitsModal';
import { InventoryItemView } from '../InventoryItemView/InventoryItemView';
import { PaymentReminder } from '../PaymentReminder/PaymentReminder';

import styles from './CurrentSubscription.module.scss';

type CurrentSubscriptionProps = {
  className?: string;
};

export const CurrentSubscription: FC<CurrentSubscriptionProps> = (props) => {
  const { className } = props;

  const { formatNumber, formatMessage } = useIntl();

  const currentSubscription = useAppSelector(
    billingModel.selectors.selectCurrentSubscription,
  );

  const availableAddons = useAppSelector(
    billingModel.selectors.selectAvailableAddons,
  );

  const isShopAvailable = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Show_BillingShop,
    ),
  );

  const hasActiveSubscription = Boolean(currentSubscription);
  const price = Number(currentSubscription?.Model?.Price ?? 0);
  const currency = currentSubscription?.Model?.Currency ?? 'USD';
  const cost = formatNumber(price, {
    style: 'currency',
    maximumFractionDigits: 2,
    currencyDisplay: 'narrowSymbol',
    currency,
  });

  return (
    <div className={cn(styles.container, className)}>
      <header className={styles.header}>
        <h4 className="h4">
          {formatMessage({
            id: 'subscription.currentSubscription.header',
            defaultMessage: 'Current Subscription',
          })}
        </h4>
      </header>

      {hasActiveSubscription ? (
        <>
          <InventoryItemView
            className={styles.inventoryItem}
            inventoryItem={currentSubscription as ProductState}
            cost={cost}
          />

          <BillingPeriod
            startDate={currentSubscription?.Start?.toDate() as Date}
            endDate={currentSubscription?.End?.toDate() as Date}
          />

          <PaymentReminder />
        </>
      ) : (
        <NoSubscription />
      )}

      {hasActiveSubscription &&
        isShopAvailable &&
        availableAddons.length > 0 && (
          <TopUpLimitsModal availableAddons={availableAddons} />
        )}
    </div>
  );
};
