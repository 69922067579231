import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';
import { difference, uniq } from 'lodash-es';

import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { LoadingStateType, SliceName } from '@/shared/config';

import { deleteAsset, setMedicalImageViewOptions } from './assetsSlice.thunks';

export const assetsAdapter = createEntityAdapter<Asset>({
  selectId: (asset: Asset) => asset?.ID,
});

const initialState = {
  guardedIDs: [] as string[],
  loading: 'idle' as LoadingStateType,
};

const assetState = assetsAdapter.getInitialState(initialState);

type AssetState = typeof assetState;

const setNewestOne = (state: AssetState, action: PayloadAction<Asset>) => {
  const currentRevisionNumber =
    state.entities[action.payload.ID]?.Revision?.Number ?? 0;
  const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

  if (payloadRevisionNumber > currentRevisionNumber) {
    assetsAdapter.setOne(state, action.payload);
  }
};

const assetsSlice = createSlice({
  name: SliceName.assets,
  initialState: assetState,
  reducers: {
    addOne: (state, action: PayloadAction<Asset>) =>
      assetsAdapter.addOne(state as AssetState, action.payload),
    addMany: (state, action: PayloadAction<Asset[]>) =>
      assetsAdapter.addMany(state as AssetState, action.payload),
    setAll: (state, action: PayloadAction<Asset[]>) =>
      assetsAdapter.setAll(state as AssetState, action.payload),
    upsertMany: (state, action: PayloadAction<Asset[]>) =>
      assetsAdapter.upsertMany(state as AssetState, action.payload),
    updateMany: (state, action: PayloadAction<Update<Asset>[]>) =>
      assetsAdapter.updateMany(state as AssetState, action.payload),
    setNewestOne: (state, action: PayloadAction<Asset>) =>
      setNewestOne(state as AssetState, action),
    setMany: (state, action: PayloadAction<Asset[]>) =>
      assetsAdapter.setMany(state as AssetState, action.payload),
    removeOne: (state, action: PayloadAction<string>) =>
      assetsAdapter.removeOne(state as AssetState, action.payload),
    removeAll: (state) => assetsAdapter.removeAll(state as AssetState),
    initGuardedIDs: (state, action: PayloadAction<string[]>) => {
      state.guardedIDs = action.payload;
    },
    addGuardedIDs: (state, action: PayloadAction<string[]>) => {
      const updatedIDs = uniq(state.guardedIDs.concat(action.payload));
      state.guardedIDs = updatedIDs;
    },
    removeAllButNoGuarded: (state) => {
      const ids = difference(state.ids, state.guardedIDs);
      assetsAdapter.removeMany(state as AssetState, ids);
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
    reset: () => assetsAdapter.getInitialState(initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(deleteAsset.fulfilled, (state, { payload }) => {
      if (payload?.Asset) {
        assetsAdapter.removeOne(state as AssetState, payload.Asset.ID);
      }
    });
    builder.addCase(
      setMedicalImageViewOptions.fulfilled,
      (state, { payload }) => {
        if (payload.Asset) {
          setNewestOne(state as AssetState, {
            type: `${SliceName.assets}/setNewestOne`,
            payload: payload.Asset,
          });
        }
      },
    );
  },
});

export const { actions } = assetsSlice;

export default assetsSlice.reducer;
