import { isArray, mergeWith } from 'lodash-es';

import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

import { collectConditionCodesFromGroups } from '../lib/collectConditionCodesFromGroups';

type ConditionGroupByCode = Record<ConditionCode, ConditionGroups>;
type ChildConditionGroupByCode = Record<ConditionCode, ChildConditionGroups>;
type ChildConditionCodesByParentCode = Record<ConditionCode, ConditionCode[]>;

export const RED_CODES = [
  ConditionCode.VoidsInTheFilling,
  ConditionCode.InternalResorption,
  ConditionCode.Child_EndoTreated_Complications_RootPerforation,
  ConditionCode.Child_EndoTreated_Quality_VoidsPresentInTheRootFilling,
  ConditionCode.HorizontalDisplacement,
  ConditionCode.LateralRadiolucency,
  ConditionCode.Missing,
  ConditionCode.Child_EndoTreated_Obturation_Overfilling,
  ConditionCode.Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal,
  ConditionCode.CrownDefectOver50pct,
  ConditionCode.VerticalRootFracture,
  ConditionCode.Overhang,
  ConditionCode.PDLSpaceWidening_AlongTheRoot,
  ConditionCode.PDLSpaceWidening_InPeriapicalRegion,
  ConditionCode.RestorationFracture,
  ConditionCode.CrownFracture,
  ConditionCode.Attrition,
  ConditionCode.ForeignBody,
  ConditionCode.DentalCalculus,
  ConditionCode.LackOfInterproximalContact,
  ConditionCode.Impaction,
  ConditionCode.HorizontalRootFracture,
  ConditionCode.RootFragment,
  ConditionCode.Child_EndoTreated_Obturation_MissedCanal,
  ConditionCode.Overeruption,
  ConditionCode.OpenMargin,
  ConditionCode.FurcationLesion,
  ConditionCode.EctopicEruption,
  ConditionCode.SecondaryCaries,
  ConditionCode.CanalObliteration,
  ConditionCode.CariesSigns,
  ConditionCode.Abfraction,
  ConditionCode.Child_EndoTreated_Obturation_ShortFilling,
  ConditionCode.Hypercementosis,
  ConditionCode.PeriodontalBoneLoss,
  ConditionCode.Child_EndoTreated_Quality_VoidsInThePostArea,
  ConditionCode.Extrusion,
  ConditionCode.Intrusion,
  ConditionCode.LateralLuxation,
  ConditionCode.CervicalResorption,
  ConditionCode.PreEruptiveResorption,
  ConditionCode.ExternalResorption,
  ConditionCode.PeriapicalRadiolucency,
  ConditionCode.PeriapicalRadiopacity,
  ConditionCode.SingsOfCommunicationWithMaxillarySinus,
  ConditionCode.SignsOfInferiorAlveolarCanalInvolvement,
  ConditionCode.PeriImplantitis,
];

export const PURPLE_CODES = [
  ConditionCode.Child_EndoTreated_Obturation_AdequateObturation,
  ConditionCode.ArtificialCrown,
  ConditionCode.Child_EndoTreated_Complications_RootPerforation,
  ConditionCode.IndirectRestoration,
  ConditionCode.Pontic,
  ConditionCode.Child_EndoTreated_PostAndCore_CastPostAndCore,
  ConditionCode.Pulpotomy,
  ConditionCode.Implant,
  ConditionCode.Child_EndoTreated_PostAndCore_FiberglassPost,
  ConditionCode.Child_EndoTreated_Obturation_MissedCanal,
  ConditionCode.EndoTreated,
  ConditionCode.OrthodonticAppliance,
  ConditionCode.Filling,
  ConditionCode.Apicoectomy,
  ConditionCode.Child_EndoTreated_PostAndCore_MetalPost,
  ConditionCode.Child_EndoTreated_Quality_AdequateDensity,
  ConditionCode.HealingAbutment,
  ConditionCode.EndodonticAccess,
];

export const YELLOW_CODES = [
  ConditionCode.CariesSigns,
  ConditionCode.PDLSpaceWidening_AlongTheRoot,
  ConditionCode.PDLSpaceWidening_InPeriapicalRegion,
  ConditionCode.PeriapicalRadiolucency,
  ConditionCode.PeriapicalRadiopacity,
];

export enum ConditionGroups {
  toothType = 'type',
  rootsNumbers = 'rootsNumbers',
  canalsNumbers = 'canalsNumbers',
  perio = 'perio',
  position = 'position',
  crown = 'crown',
  root = 'root',
  endo = 'endo',
  implant = 'implant',
  periradicularPathologies = 'periradicularPathologies',
  mucosalThickening = 'mucosalThickening',
}

export enum ChildConditionGroups {
  depth = 'depth',
  surface = 'surface',
  perioSeverity = 'perioSeverity',
  perioType = 'perioType',
  impaction = 'impaction',
  obturation = 'obturation',
  quality = 'quality',
  complications = 'complications',
  buildup = 'buildup',
  construction = 'construction',
  involvement = 'involvement',
  mucosalThickeningType = 'mucosalThickeningType',
}

export type ConditionGroup = ConditionGroups | ChildConditionGroups;

export const ONE_OF_CONDITION_GROUPS = [ConditionGroups.toothType];

export const TOOTH_TYPE_GROUP = {
  [ConditionGroups.toothType]: [
    ConditionCode.CodeTooth,
    ConditionCode.Implant,
    ConditionCode.Pontic,
    ConditionCode.RootFragment,
    ConditionCode.Missing,
    ConditionCode.ToothGerm,
  ],
};

export const TOOTH_ANATOMY_GROUPS = {
  [ConditionGroups.rootsNumbers]: [
    ConditionCode.Roots1,
    ConditionCode.Roots2,
    ConditionCode.Roots3,
    ConditionCode.Roots4,
  ],
  [ConditionGroups.canalsNumbers]: [
    ConditionCode.Canals1,
    ConditionCode.Canals2,
    ConditionCode.Canals3,
    ConditionCode.Canals4,
    ConditionCode.Canals5,
  ],
};

export const TOOTH_GENERAL_GROUPS = {
  [ConditionGroups.perio]: [
    ConditionCode.PeriodontalBoneLoss,
    ConditionCode.FurcationLesion,
    ConditionCode.DentalCalculus,
  ],
  [ConditionGroups.position]: [
    ConditionCode.Impaction,
    ConditionCode.HorizontalDisplacement,
    ConditionCode.Overeruption,
    ConditionCode.EctopicEruption,
    ConditionCode.Extrusion,
    ConditionCode.Intrusion,
    ConditionCode.LateralLuxation,
  ],
  [ConditionGroups.crown]: [
    ConditionCode.Filling,
    ConditionCode.ArtificialCrown,
    ConditionCode.IndirectRestoration,
    ConditionCode.OrthodonticAppliance,
    ConditionCode.HealingAbutment,
    ConditionCode.PulpStone,
    ConditionCode.Attrition,
    ConditionCode.Abfraction,
    ConditionCode.CrownFracture,
    ConditionCode.RestorationFracture,
    ConditionCode.CrownDefectOver50pct,
    ConditionCode.CervicalResorption,
    ConditionCode.PreEruptiveResorption,
    ConditionCode.VoidsInTheFilling,
    ConditionCode.Overhang,
    ConditionCode.OpenMargin,
    ConditionCode.LackOfInterproximalContact,
    ConditionCode.EndodonticAccess,
    ConditionCode.ToothStump,
    ConditionCode.CariesSigns,
    ConditionCode.SecondaryCaries,
  ],
  [ConditionGroups.root]: [
    ConditionCode.InternalResorption,
    ConditionCode.ExternalResorption,
    ConditionCode.HorizontalRootFracture,
    ConditionCode.VerticalRootFracture,
    ConditionCode.CanalObliteration,
    ConditionCode.Hypercementosis,
    ConditionCode.Apicoectomy,
  ],
  [ConditionGroups.endo]: [ConditionCode.Pulpotomy, ConditionCode.EndoTreated],
  [ConditionGroups.implant]: [ConditionCode.PeriImplantitis],
  [ConditionGroups.periradicularPathologies]: [
    ConditionCode.PeriapicalRadiolucency,
    ConditionCode.LateralRadiolucency,
    ConditionCode.PDLSpaceWidening_InPeriapicalRegion,
    ConditionCode.PDLSpaceWidening_AlongTheRoot,
    ConditionCode.PeriapicalRadiopacity,
    ConditionCode.SingsOfCommunicationWithMaxillarySinus,
    ConditionCode.SignsOfInferiorAlveolarCanalInvolvement,
  ],
};

export const CONDITION_CODES_BY_GROUPS = {
  ...TOOTH_TYPE_GROUP,
  ...TOOTH_ANATOMY_GROUPS,
  ...TOOTH_GENERAL_GROUPS,
};

export const FLAT_CONDITION_GROUP_CODES = collectConditionCodesFromGroups(
  CONDITION_CODES_BY_GROUPS,
);

const PERIODONTAL_BONE_LOSS_CHILD_GROUP = {
  [ConditionCode.PeriodontalBoneLoss]: {
    [ChildConditionGroups.perioSeverity]: [
      ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild,
      ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate,
      ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe,
    ],
    [ChildConditionGroups.perioType]: [
      ConditionCode.Child_PeriodontalBoneLoss_Type_Horizontal,
      ConditionCode.Child_PeriodontalBoneLoss_Type_Vertical,
      ConditionCode.Child_PeriodontalBoneLoss_Type_Mixed,
    ],
  },
};

const IMPACTION_GROUP = {
  [ConditionCode.Impaction]: {
    [ChildConditionGroups.impaction]: [
      ConditionCode.Child_Impaction_Subtype_CompleteBony,
      ConditionCode.Child_Impaction_Subtype_PartiallyBony,
    ],
  },
};

const FILLING_CHILD_GROUP = {
  [ConditionCode.Filling]: {
    [ChildConditionGroups.depth]: [
      ConditionCode.Child_DefectDepth_Enamel,
      ConditionCode.Child_DefectDepth_Dentin,
      ConditionCode.Child_DefectDepth_WithPulpExposure,
      ConditionCode.Child_DefectDepth_Root,
      ConditionCode.Child_DefectDepth_NotDefined,
    ],
    [ChildConditionGroups.surface]: [
      ConditionCode.Child_DefectSurface_Mesial,
      ConditionCode.Child_DefectSurface_Distal,
      ConditionCode.Child_DefectSurface_Occlusial,
      ConditionCode.Child_DefectSurface_IncisalEdge,
      ConditionCode.Child_DefectSurface_Buccal,
      ConditionCode.Child_DefectSurface_Vestibular,
      ConditionCode.Child_DefectSurface_Lingual,
      ConditionCode.Child_DefectSurface_NotDefined,
    ],
    [ChildConditionGroups.buildup]: [ConditionCode.Child_Filling_BuildUp],
  },
};

const CROWN_FRACTURE_CHILD_GROUP = {
  [ConditionCode.CrownFracture]: {
    [ChildConditionGroups.depth]: [
      ConditionCode.Child_DefectDepth_Enamel,
      ConditionCode.Child_DefectDepth_Dentin,
      ConditionCode.Child_DefectDepth_WithPulpExposure,
      ConditionCode.Child_DefectDepth_CrownRoot,
    ],
  },
};

const CARIES_CHILD_GROUP = {
  [ConditionCode.CariesSigns]: {
    [ChildConditionGroups.surface]: [
      ConditionCode.Child_DefectSurface_Mesial,
      ConditionCode.Child_DefectSurface_Distal,
      ConditionCode.Child_DefectSurface_Occlusial,
      ConditionCode.Child_DefectSurface_IncisalEdge,
      ConditionCode.Child_DefectSurface_Buccal,
      ConditionCode.Child_DefectSurface_Vestibular,
      ConditionCode.Child_DefectSurface_Lingual,
      ConditionCode.Child_DefectSurface_NotDefined,
    ],
    [ChildConditionGroups.depth]: [
      ConditionCode.Child_DefectDepth_Initial,
      ConditionCode.Child_DefectDepth_Enamel,
      ConditionCode.Child_DefectDepth_Dentin,
      ConditionCode.Child_DefectDepth_WithPulpExposure,
      ConditionCode.Child_DefectDepth_Root,
      ConditionCode.Child_DefectDepth_NotDefined,
    ],
  },
};

const SECONDARY_CARIES_CHILD_GROUP = {
  [ConditionCode.SecondaryCaries]: {
    [ChildConditionGroups.depth]: [
      ConditionCode.Child_DefectDepth_Initial,
      ConditionCode.Child_DefectDepth_Enamel,
      ConditionCode.Child_DefectDepth_Dentin,
      ConditionCode.Child_DefectDepth_WithPulpExposure,
      ConditionCode.Child_DefectDepth_Root,
      ConditionCode.Child_DefectDepth_NotDefined,
    ],
    [ChildConditionGroups.surface]: [
      ConditionCode.Child_DefectSurface_Mesial,
      ConditionCode.Child_DefectSurface_Distal,
      ConditionCode.Child_DefectSurface_Occlusial,
      ConditionCode.Child_DefectSurface_IncisalEdge,
      ConditionCode.Child_DefectSurface_Buccal,
      ConditionCode.Child_DefectSurface_Vestibular,
      ConditionCode.Child_DefectSurface_Lingual,
      ConditionCode.Child_DefectSurface_NotDefined,
    ],
  },
};

export const ENDO_CHILD_GROUP = {
  [ConditionCode.EndoTreated]: {
    [ChildConditionGroups.obturation]: [
      ConditionCode.Child_EndoTreated_Obturation_AdequateObturation,
      ConditionCode.Child_EndoTreated_Obturation_ShortFilling,
      ConditionCode.Child_EndoTreated_Obturation_Overfilling,
      ConditionCode.Child_EndoTreated_Obturation_MissedCanal,
    ],
    [ChildConditionGroups.quality]: [
      ConditionCode.Child_EndoTreated_Quality_AdequateDensity,
      ConditionCode.Child_EndoTreated_Quality_VoidsPresentInTheRootFilling,
      ConditionCode.Child_EndoTreated_Quality_VoidsInThePostArea,
    ],
    [ChildConditionGroups.construction]: [
      ConditionCode.Child_EndoTreated_PostAndCore_CastPostAndCore,
      ConditionCode.Child_EndoTreated_PostAndCore_FiberglassPost,
      ConditionCode.Child_EndoTreated_PostAndCore_MetalPost,
      ConditionCode.Child_EndoTreated_PostAndCore_Post,
    ],
    [ChildConditionGroups.complications]: [
      ConditionCode.Child_EndoTreated_Complications_RootPerforation,
      ConditionCode.Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal,
    ],
  },
};

export const MUCOSAL_THICKENING_TYPE_CHILD_GROUP = {
  [ConditionCode.MucosalThickening]: {
    [ChildConditionGroups.mucosalThickeningType]: [
      ConditionCode.Child_MucosalThickening_Type_Focal,
      ConditionCode.Child_MucosalThickening_Type_Diffused,
      ConditionCode.Child_MucosalThickening_Type_CompleteOrPartialObstruction,
    ],
  },
};

export const CHILD_CONDITION_GROUPS = {
  ...PERIODONTAL_BONE_LOSS_CHILD_GROUP,
  ...IMPACTION_GROUP,
  ...FILLING_CHILD_GROUP,
  ...CROWN_FRACTURE_CHILD_GROUP,
  ...CARIES_CHILD_GROUP,
  ...SECONDARY_CARIES_CHILD_GROUP,
  ...ENDO_CHILD_GROUP,
  ...MUCOSAL_THICKENING_TYPE_CHILD_GROUP,
};

export const CONDITION_GROUP_BY_CODE = Object.entries(
  CONDITION_CODES_BY_GROUPS,
).reduce((acc: ConditionGroupByCode, [group, conditionCodes]) => {
  conditionCodes.forEach((code) => {
    acc[code] = group as ConditionGroups;
  });
  return acc;
}, {} as ConditionGroupByCode);

function mergeCustomizer(objValue: unknown, srcValue: unknown) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export const CHILD_CONDITION_GROUP_BY_CODE = Object.entries(
  Object.values(CHILD_CONDITION_GROUPS).reduce(
    (acc, curr) => mergeWith(acc, curr, mergeCustomizer),
    {},
  ),
).reduce((acc: ChildConditionGroupByCode, [group, conditionCodes]) => {
  (conditionCodes as ConditionCode[]).forEach((code) => {
    acc[code] = group as ChildConditionGroups;
  });
  return acc;
}, {} as ChildConditionGroupByCode);

export const CHILD_CONDITION_CODES_BY_PARENT_CODE = Object.entries(
  CHILD_CONDITION_GROUPS,
).reduce(
  (acc: ChildConditionCodesByParentCode, [parentCode, conditionCodes]) => {
    acc[parentCode as unknown as ConditionCode] = Object.values(
      conditionCodes,
    ).flatMap((code) => code);
    return acc;
  },
  {} as ChildConditionCodesByParentCode,
);
