import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useIntl } from 'react-intl/lib';
import { equals } from 'ramda';

import { LocationStateType, PATHS } from '@/shared/config';
import {
  Button,
  Column,
  Layout,
  Spinner,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui';
import { useAppSelector, useMedia } from '@/shared/hooks';
import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import { scrollToElementByID, trackPageView } from '@/shared/lib';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { getReportSignStatus, ReportSettingsToggle } from '@/entities/reports';
import { conditionModel } from '@/entities/condition';
import { toothModel } from '@/entities/tooth';
import { ConfirmModal } from '@/entities/modal';
import { organizationModel } from '@/entities/organization';

import { useReport_DEPRECATED } from '@/features/reportStream';
import { MaskTooltip, MaskFilters } from '@/features/renderMasks';
import { useSignReport } from '@/features/signReport';

import { ToothCard } from '@/widgets/ToothCard';
import { AddConditionModal } from '@/widgets/AddConditionModal';
import { Conclusion } from '@/widgets/Conclusion';
import { ReportHeading } from '@/widgets/ReportHeading';
import { ZoomedMedicalImageModal } from '@/widgets/ZoomedMedicalImageModal';
import { NonDentalFindings } from '@/widgets/NonDentalFindings';
import {
  TeethNumberingModal,
  useGetTeethNumberingModalImage,
} from '@/widgets/TeethNumberingModal';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';

import styles from './GpReport.module.scss';
import { GpToothChart } from './ui/GpToothChart/GpToothChart';
import { PanoReformatRender } from './ui/PanoReformatRender/PanoReformatRender';
import { Toolbar } from './ui/Toolbar/Toolbar';

type ReportProps = {
  className?: string;
};

export const GpReport: FC<ReportProps> = (props) => {
  const { className } = props;

  const { patientID = '', reportID = '' } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const location = useLocation();

  const showNonDentalFindings = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Show_NonDentalFindings,
    ),
  );

  const conditionLoading = useAppSelector(
    conditionModel.selectors.selectLoading,
  );

  const toothLoading = useAppSelector(toothModel.selectors.selectLoading);

  const [, setCurrentToothID] = useState<string>('');

  const { isMobile } = useMedia();

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const scrollToElementID = locationState?.toothID;

  const lastPositionReport = locationState?.lastPositionReport;

  const { report, isReportSigned, isReportLoading, isAllTeethApproved } =
    useReport_DEPRECATED(reportID, patientID);

  const teeth = useAppSelector(
    toothModel.selectors.selectTeethToShow(reportID),
  );

  const {
    panoramicImage,
    panoImageURL,
    panoAssetID,
    CBCTPanoImage,
    reportViewOptions,
    CBCTPanoramaSplitURL,
    CBCTPanoramaURL,
    CBCTPanoramaAssetID,
    modalWidth,
  } = useGetTeethNumberingModalImage(report as Report);

  const maxFaxConditions = useAppSelector(
    conditionModel.selectors.selectMaxFax,
  );

  const reportSignStatus = getReportSignStatus(report);

  const openConfirmSignReportModal = useSignReport(isAllTeethApproved);

  const handlePrintButton = () => {
    navigate(generatePath(`../${PATHS.preview}`, { patientID, reportID }), {
      state: {
        ...locationState,
        lastPositionReport: window.pageYOffset,
        toothID: undefined,
      },
      relative: 'path',
    });
  };

  const handleSlicesClick = (toothID: string) => {
    // TODO: is it usable??
    setCurrentToothID((prevState) => {
      if (equals(toothID, prevState)) {
        return prevState;
      }

      return toothID;
    });
  };

  const showToothChart =
    conditionLoading === 'succeeded' && toothLoading === 'succeeded';

  useEffect(() => {
    trackPageView('GpReport Page Viewed');

    if (scrollToElementID) {
      scrollToElementByID(scrollToElementID, 'auto');
    }

    if (!scrollToElementID && lastPositionReport) {
      window.scrollTo(0, lastPositionReport);
    }
  }, []);

  if (isReportLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner className={styles.spinner} size={96} />
      </div>
    );
  }

  if (!report) {
    return null;
  }

  const teethNumberingImage =
    report.Type === ReportType.ReportType_Pano_GP
      ? panoramicImage
      : CBCTPanoImage;

  const panoImage =
    report.Type === ReportType.ReportType_CBCT_GP
      ? {
          viewOptions: reportViewOptions,
          assetID: CBCTPanoramaAssetID ?? '',
          panoramaURL: CBCTPanoramaURL,
          panoramaSplitURL: CBCTPanoramaSplitURL,
        }
      : {
          viewOptions: reportViewOptions,
          assetID: panoAssetID ?? '',
          panoramaURL: panoImageURL,
        };

  const reportImageLoading = !panoImage.assetID;

  return (
    <>
      <Layout.Content>
        <Layout.Main>
          <Layout.Content className={cn(styles.container, className)}>
            <WidgetLayout className={styles.columnWrapper}>
              <Column className={styles.rightColumn}>
                {/* <ReferralRecommendations reportID={reportID} /> */}

                {maxFaxConditions.length > 0 && showNonDentalFindings && (
                  <NonDentalFindings conditions={maxFaxConditions} />
                )}

                {/* TODO: Refactor for cropped tooth images */}
                {/* {report.Type === ReportType.ReportType_IOXRay_GP && <WidgetCard> */}
                {/*   <Switch onChange={() => {}}  checked> */}
                {/*     <FormattedMessage id="report.cropped" defaultMessage="Cropped images" /> */}
                {/*   </Switch> */}
                {/* </WidgetCard>} */}

                {teeth.map((tooth) => (
                  <ToothCard
                    key={tooth.ID}
                    id={tooth.ID}
                    patientID={patientID}
                    reportID={reportID}
                    onSlicesClick={handleSlicesClick}
                  />
                ))}

                <Conclusion />
              </Column>
              <Column className={styles.column}>
                <div className={styles.leftFixedColumn}>
                  <ReportHeading
                    report={report}
                    isReportLoading={isReportLoading}
                  />
                  <WidgetCard
                    className={styles.panoImageWidget}
                    loading={reportImageLoading}
                  >
                    <PanoReformatRender>
                      <Toolbar />
                      <MaskFilters className={styles.maskFilters} />
                      <MaskTooltip />
                    </PanoReformatRender>
                  </WidgetCard>

                  <WidgetCard className={styles.toothChart}>
                    {showToothChart && <GpToothChart />}
                  </WidgetCard>
                </div>
              </Column>
            </WidgetLayout>
          </Layout.Content>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer settingsFooter className={styles.footerContainer}>
        <div className={styles.footerWrapper}>
          <ReportSettingsToggle className={styles.settingsToggle} />

          <Button
            variant={isReportSigned ? 'primary' : 'tertiary'}
            onClick={handlePrintButton}
            icon={isReportSigned ? 'check' : undefined}
            size={isMobile ? 'medium' : 'large'}
          >
            {isReportSigned ? (
              <FormattedMessage
                id="report.printReport"
                defaultMessage="Print report"
              />
            ) : (
              <FormattedMessage
                id="report.printReportWithoutSignature"
                defaultMessage="Print report without signature"
              />
            )}
          </Button>

          {!isReportSigned && (
            <Button
              icon="pen"
              disabled={
                reportSignStatus === 'withoutSign' ||
                !report.YourPermissions?.CanChangeToothApproved
              }
              onClick={openConfirmSignReportModal}
              size={isMobile ? 'medium' : 'large'}
            >
              {isAllTeethApproved ? (
                <FormattedMessage id="report.sign" defaultMessage="Sign" />
              ) : (
                <FormattedMessage
                  id="report.approveAndSign"
                  defaultMessage="Approve all and sign"
                />
              )}
            </Button>
          )}
        </div>
      </Layout.Footer>

      <TeethNumberingModal
        title={formatMessage({
          id: 'report.EditTeethNumbers',
          defaultMessage: 'Edit teeth numbers',
        })}
        patientID={patientID}
        reportID={reportID}
        modalWidth={modalWidth}
        reportImage={teethNumberingImage}
      />

      <AddConditionModal />

      <ZoomedMedicalImageModal />

      <GetFullAccessModal />

      <ConfirmModal />
    </>
  );
};
