// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/core/svc_report.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { AddToothDisplaySliceReq, AddToothDisplaySliceResp, ApproveAllTeethAndSignReportReq, ApproveAllTeethAndSignReportResp, ApproveReportToothReq, ApproveReportToothResp, CreateReportToothConditionsReq, CreateReportToothConditionsResp, DeleteReportReq, DeleteReportResp, DisapproveReportToothReq, DisapproveReportToothResp, GetReportsByStudyAssetAttributesReq, GetReportsByStudyAssetAttributesResp, MarkToothAsDeletedReq, MarkToothAsDeletedResp, MarkToothDetectionAsDeletedReq, MarkToothDetectionAsDeletedResp, PrintReportReq, PrintReportResp, RemoveToothDisplaySliceReq, RemoveToothDisplaySliceResp, ReportDataStreamReq, ReportDataStreamResp, RequestReportReq, RequestReportResp, ResetReportToothReq, ResetReportToothResp, SetImageMetaViewOptionsReq, SetImageMetaViewOptionsResp, SetReportConclusionReq, SetReportConclusionResp, SetReportMedicalImageViewOptionsReq, SetReportMedicalImageViewOptionsResp, SetReportPrintSettingsReq, SetReportPrintSettingsResp, SetReportSettingsReq, SetReportSettingsResp, SetReportToothCommentReq, SetReportToothCommentResp, SetReportToothConditionsUserDecisionReq, SetReportToothConditionsUserDecisionResp, SetReportToothConditionUserScoreAndApproveToothReq, SetReportToothConditionUserScoreAndApproveToothResp, SetROIReq, SetROIResp, SetToothDetectionNumerationAndReviveReq, SetToothDetectionNumerationAndReviveResp, SetToothNumerationAndReviveReq, SetToothNumerationAndReviveResp, SignReportReq, SignReportResp } from "./svc_report_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: ReportID
 * - type: NotFoundError, Entity: report (if report not found by ReportID)
 * - type: BadRequestError, Reason: report/tooth deleted (if remote report/tooth found by ReportID)
 * - type: NotFoundError, Entity: tooth (if tooth not found by ToothID)
 *
 * @generated from service com.diagnocat.core.ReportService
 */
export const ReportService = {
  typeName: "com.diagnocat.core.ReportService",
  methods: {
    /**
     * @generated from rpc com.diagnocat.core.ReportService.ReportDataStream
     */
    reportDataStream: {
      name: "ReportDataStream",
      I: ReportDataStreamReq,
      O: ReportDataStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Request report
     *
     * Permission: `PatientPermissions.CanRequestReport`
     *
     * @generated from rpc com.diagnocat.core.ReportService.RequestReport
     */
    requestReport: {
      name: "RequestReport",
      I: RequestReportReq,
      O: RequestReportResp,
      kind: MethodKind.Unary,
    },
    /**
     * Get reports (ReportType_IOXRay_GP && ReportType_Pano_GP)
     * by Asset.Attributes (AssetType_Study_IntraoralXRay || AssetType_Study_PanoramicXRay)
     *
     * If the request contains several attributes,
     * then only reports (study_assets) with all mentioned attributes are returned
     *
     * Permission: `ReportPermissions.CanView`
     *
     * @generated from rpc com.diagnocat.core.ReportService.GetReportsByStudyAssetAttributes
     */
    getReportsByStudyAssetAttributes: {
      name: "GetReportsByStudyAssetAttributes",
      I: GetReportsByStudyAssetAttributesReq,
      O: GetReportsByStudyAssetAttributesResp,
      kind: MethodKind.Unary,
    },
    /**
     * Mark report as deleted using report id
     * - this report mark as deleted
     * - all invitations to this report are closed
     *
     * Permission: `ReportPermissions.CanDelete`
     *
     * @generated from rpc com.diagnocat.core.ReportService.DeleteReport
     */
    deleteReport: {
      name: "DeleteReport",
      I: DeleteReportReq,
      O: DeleteReportResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set region of interest (slice of tooth identifiers) and un-sign report
     *
     * Permission: `ReportPermissions.CanChangeROI`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SetROI
     */
    setROI: {
      name: "SetROI",
      I: SetROIReq,
      O: SetROIResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set legacy conclusion and un-sign report
     *
     * Permission: `ReportPermissions.CanChangeConclusion`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SetReportConclusion
     */
    setReportConclusion: {
      name: "SetReportConclusion",
      I: SetReportConclusionReq,
      O: SetReportConclusionResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set report settings and un-sign report
     *
     * Permission: `ReportPermissions.CanChangeSettings`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SetReportSettings
     */
    setReportSettings: {
      name: "SetReportSettings",
      I: SetReportSettingsReq,
      O: SetReportSettingsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Permission: `ReportPermissions.CanChangeSettings`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SetReportPrintSettings
     */
    setReportPrintSettings: {
      name: "SetReportPrintSettings",
      I: SetReportPrintSettingsReq,
      O: SetReportPrintSettingsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Sign report and turn off `ReportPrintSettings.ShowDiagnosesProbabilities`
     *
     * Permission: `ReportPermissions.CanSign`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SignReport
     */
    signReport: {
      name: "SignReport",
      I: SignReportReq,
      O: SignReportResp,
      kind: MethodKind.Unary,
    },
    /**
     * Permission: `ReportPermissions.CanChangeReportAssets`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SetReportMedicalImageViewOptions
     */
    setReportMedicalImageViewOptions: {
      name: "SetReportMedicalImageViewOptions",
      I: SetReportMedicalImageViewOptionsReq,
      O: SetReportMedicalImageViewOptionsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set viewOptions to Report_IOXRay_GP.Image_Meta || Report_Pano_GP.Image_Meta
     *
     * Permission: `ReportPermissions.CanChangeReportAssets`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SetImageMetaViewOptions
     */
    setImageMetaViewOptions: {
      name: "SetImageMetaViewOptions",
      I: SetImageMetaViewOptionsReq,
      O: SetImageMetaViewOptionsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Generate and save a print of the report (e.g. surgical guide) while replacing the old one if exists.
     * Expected to be used for printing other report types like CBCT GP in the future.
     *
     * Permission: `ReportPermissions.CanChangeReportAssets`
     *
     * @generated from rpc com.diagnocat.core.ReportService.PrintReport
     */
    printReport: {
      name: "PrintReport",
      I: PrintReportReq,
      O: PrintReportResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set tooth comment, disapprove tooth and un-sign report
     *
     * Permission: `ReportPermissions.CanChangeToothComment`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SetReportToothComment
     */
    setReportToothComment: {
      name: "SetReportToothComment",
      I: SetReportToothCommentReq,
      O: SetReportToothCommentResp,
      kind: MethodKind.Unary,
    },
    /**
     * SetToothNumerationAndRevive: only if tooth has detections
     * - Change Tooth ISO number and SupernumeraryIndex
     * - Disapprove Tooth
     * - Revive Tooth and all ToothDetections (delete IsRemoved flags from Tooth and all ToothDetections)
     * - Return Tooth to ROI
     * - Un-sign report
     *
     * Permission: `ReportPermissions.CanChangeToothNumeration`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SetToothNumerationAndRevive
     */
    setToothNumerationAndRevive: {
      name: "SetToothNumerationAndRevive",
      I: SetToothNumerationAndReviveReq,
      O: SetToothNumerationAndReviveResp,
      kind: MethodKind.Unary,
    },
    /**
     * SetToothDetectionNumerationAndRevive:
     * - Change toothDetection ISO number and SupernumeraryIndex (replace to another Tooth)
     * - Disapprove source and target teeth
     * - Revive ToothDetection (delete IsRemoved flag from ToothDetection)
     * - Revive Tooth
     * - Return Tooth to ROI
     * - Un-sign Report
     *
     * Permission: `ReportPermissions.CanChangeToothNumeration`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SetToothDetectionNumerationAndRevive
     */
    setToothDetectionNumerationAndRevive: {
      name: "SetToothDetectionNumerationAndRevive",
      I: SetToothDetectionNumerationAndReviveReq,
      O: SetToothDetectionNumerationAndReviveResp,
      kind: MethodKind.Unary,
    },
    /**
     * MarkToothAsDeleted:
     * - Set IsRemoved flag on Tooth
     * - Disapprove Tooth
     * - Remove Tooth from ROI
     * - Un-sign Report
     *
     * Permission: `ReportPermissions.CanChangeToothNumeration`
     *
     * @generated from rpc com.diagnocat.core.ReportService.MarkToothAsDeleted
     */
    markToothAsDeleted: {
      name: "MarkToothAsDeleted",
      I: MarkToothAsDeletedReq,
      O: MarkToothAsDeletedResp,
      kind: MethodKind.Unary,
    },
    /**
     * MarkToothDetectionAsDeleted:
     * - Set IsRemoved flag on ToothDetection
     * - Set IsRemoved on Tooth, if this is the last ToothDetection being removed
     * - Disapprove Tooth
     * - Un-sign report
     *
     * Permission: `ReportPermissions.CanChangeToothNumeration`
     *
     * @generated from rpc com.diagnocat.core.ReportService.MarkToothDetectionAsDeleted
     */
    markToothDetectionAsDeleted: {
      name: "MarkToothDetectionAsDeleted",
      I: MarkToothDetectionAsDeletedReq,
      O: MarkToothDetectionAsDeletedResp,
      kind: MethodKind.Unary,
    },
    /**
     * Using this method doctor confirms that conditions on this tooth are correct
     *
     * Permission: `ReportPermissions.CanChangeToothApproved`
     *
     * @generated from rpc com.diagnocat.core.ReportService.ApproveReportTooth
     */
    approveReportTooth: {
      name: "ApproveReportTooth",
      I: ApproveReportToothReq,
      O: ApproveReportToothResp,
      kind: MethodKind.Unary,
    },
    /**
     * Disapprove tooth and un-sign report
     *
     * Permission: `ReportPermissions.CanChangeToothApproved`
     *
     * @generated from rpc com.diagnocat.core.ReportService.DisapproveReportTooth
     */
    disapproveReportTooth: {
      name: "DisapproveReportTooth",
      I: DisapproveReportToothReq,
      O: DisapproveReportToothResp,
      kind: MethodKind.Unary,
    },
    /**
     * Approve all disapprove teeth in ROI and sign report and turn off `ReportPrintSettings.ShowDiagnosesProbabilities`
     * Permission: `ReportPermissions.CanChangeToothApproved` && `ReportPermissions.CanSign`
     *
     * @generated from rpc com.diagnocat.core.ReportService.ApproveAllTeethAndSignReport
     */
    approveAllTeethAndSignReport: {
      name: "ApproveAllTeethAndSignReport",
      I: ApproveAllTeethAndSignReportReq,
      O: ApproveAllTeethAndSignReportResp,
      kind: MethodKind.Unary,
    },
    /**
     * Reset tooth && tooth conditions and un-sign report
     *
     * Permission: `ReportPermissions.CanResetTooth`
     *
     * @generated from rpc com.diagnocat.core.ReportService.ResetReportTooth
     */
    resetReportTooth: {
      name: "ResetReportTooth",
      I: ResetReportToothReq,
      O: ResetReportToothResp,
      kind: MethodKind.Unary,
    },
    /**
     * Add Asset to tooth displaySlices, disapprove tooth and un-sign report
     *
     * Permission: `ReportPermissions.CanChangeConditions`
     *
     * @generated from rpc com.diagnocat.core.ReportService.AddToothDisplaySlice
     */
    addToothDisplaySlice: {
      name: "AddToothDisplaySlice",
      I: AddToothDisplaySliceReq,
      O: AddToothDisplaySliceResp,
      kind: MethodKind.Unary,
    },
    /**
     * Remove Asset from tooth displaySlices, disapprove tooth and un-sign report
     *
     * Permission: `ReportPermissions.CanChangeToothAssets`
     *
     * @generated from rpc com.diagnocat.core.ReportService.RemoveToothDisplaySlice
     */
    removeToothDisplaySlice: {
      name: "RemoveToothDisplaySlice",
      I: RemoveToothDisplaySliceReq,
      O: RemoveToothDisplaySliceResp,
      kind: MethodKind.Unary,
    },
    /**
     * Create new condition, disapprove tooth and un-sign report
     *
     * Permission: `ReportPermissions.CanChangeConditions`
     *
     * Errors:
     * - type: BadRequestError, Reason: ROI does not contain this tooth
     *
     * @generated from rpc com.diagnocat.core.ReportService.CreateReportToothConditions
     */
    createReportToothConditions: {
      name: "CreateReportToothConditions",
      I: CreateReportToothConditionsReq,
      O: CreateReportToothConditionsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set user decision for conditions bound to one tooth, disapprove tooth and un-sign report
     *
     * Permission: `ReportPermissions.CanChangeConditions`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SetReportToothConditionsUserDecision
     */
    setReportToothConditionsUserDecision: {
      name: "SetReportToothConditionsUserDecision",
      I: SetReportToothConditionsUserDecisionReq,
      O: SetReportToothConditionsUserDecisionResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set user score for tooth condition, approve tooth and un-sign report
     *
     * Permission: `ReportPermissions.CanChangeConditions`
     *
     * @generated from rpc com.diagnocat.core.ReportService.SetReportToothConditionUserScoreAndApproveTooth
     */
    setReportToothConditionUserScoreAndApproveTooth: {
      name: "SetReportToothConditionUserScoreAndApproveTooth",
      I: SetReportToothConditionUserScoreAndApproveToothReq,
      O: SetReportToothConditionUserScoreAndApproveToothResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

