import cn from 'classnames';

import { Icon } from '@/shared/ui';
import { useFormatBytes } from '@/shared/hooks';

import styles from './UploadPhotoFile.module.scss';

type UploadPhotoFileProps = {
  file: File;
  onDelete: () => void;
  testID?: string;
  className?: string;
};

export const UploadPhotoFile = (props: UploadPhotoFileProps) => {
  const { file, onDelete, className, testID } = props;

  const formatBytes = useFormatBytes();

  const fileName = file.name;
  const fileSize = file.size;

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <Icon className={styles.fileIcon} name="doc" size={32} />

      <h4 className={cn(styles.name, 'p2')}>{fileName}</h4>

      <span className={cn(styles.size, 'p2')}>{formatBytes(fileSize)}</span>

      <button className={styles.deleteButton} type="button" onClick={onDelete}>
        <Icon className={styles.deleteIcon} name="delete" size={32} />
      </button>
    </div>
  );
};
