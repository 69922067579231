// NOTE: Composed entities selector which is not fit to the feature slice.
import { createSelector } from '@reduxjs/toolkit';
import { groupBy } from 'lodash-es';

import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

import { reportsModel } from '@/entities/reports';
import {
  CHILD_CONDITION_CODES_BY_PARENT_CODE,
  FLAT_CONDITION_GROUP_CODES,
  conditionModel,
  getToothStatus,
  makeCompactConditions,
  processLogicalConditions,
  splitConditionCodes,
  splitConditions,
} from '@/entities/condition';
import { organizationModel } from '@/entities/organization';
import { allowedToothConditionsModel } from '@/entities/allowedToothConditions';

export const selectToothStatus = (
  toothID: string,
  ignoreLowProbability = false,
) =>
  createSelector(
    conditionModel.selectors.selectByToothID(toothID),
    reportsModel.selectors.selectLowProbabilityMode,
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_FDA_NonAidedVersion,
    ),
    (toothConditions, lowProbabilityMode, isFDA, isFDANonAided) =>
      getToothStatus(toothConditions, {
        showLowProbability: lowProbabilityMode,
        isFDA,
        isFDANonAided,
        ignoreLowProbability,
      }),
  );

// NOTE: This selector collects all allowed tooth conditions received from the backend
// in combination with all other allowed conditions for the tooth in its current state,
// which is regulating by condition engine on the backend (allowedToothConditionsHint).
export const selectAllowedConditions = (toothID: string) =>
  createSelector(
    conditionModel.selectors.selectByToothID(toothID),
    allowedToothConditionsModel.selectors.selectById(toothID),
    (toothConditions, allowedToothConditionsHint) => {
      // Collection of all possible conditions for the tooth
      const allowedToothConditions: Condition[] = [];

      // Split allowed tooth condition codes by parent and children codes
      const {
        parentCodes: allowedParentCodes,
        childrenCodes: allowedChildrenCodes,
      } = splitConditionCodes(allowedToothConditionsHint?.ConditionCodes);

      // Split existed tooth conditions by parent and children conditions
      const { conditions, childrenConditions } =
        splitConditions(toothConditions);

      // Filtered parent condition codes by allowed parent conditions code for the tooth
      const allowedParentConditionCodes = FLAT_CONDITION_GROUP_CODES.filter(
        (parentCode) => allowedParentCodes.includes(parentCode),
      );

      // Iterate allowedParentConditionCodes to include all existing allowed tooth parent conditions and
      // add other possible allowed condition for the possbility to add them by user.
      // NOTE: Spead operator because conditions grouping by code to collect duplicates in one place
      allowedParentConditionCodes.forEach((code) => {
        if (code in conditions) {
          allowedToothConditions.push(...conditions[code]);
        } else {
          const emptyCondition = new Condition({
            Attribution: { case: 'Tooth', value: { ToothID: toothID } },
            Code: code,
          });

          allowedToothConditions.push(emptyCondition);
        }

        if (code in CHILD_CONDITION_CODES_BY_PARENT_CODE) {
          const groupedChildrenConditions = groupBy(
            childrenConditions[code],
            'Code',
          );
          // WARN: I don't know if it's necessary step.
          //       Is there a case when condition should contain only part of children conditions?
          //
          // const allowedChildrenConditionCodes = allowedChildrenCodes;
          const allowedChildrenConditionCodes =
            CHILD_CONDITION_CODES_BY_PARENT_CODE[code].filter((childrenCode) =>
              allowedChildrenCodes.includes(childrenCode),
            );
          if (code === 1092) {
            console.log('debug 1092');
            console.log({
              groupedChildrenConditions,
              childrenConditions: childrenConditions[code],
              allowedChildrenConditionCodes,
            });
          }

          // Iterate allowedChildrenConditionCodes to include all existing allowed tooth children conditions and
          // add other possible allowed conditions for the possbility to add them by user.
          // NOTE: Spead operator because conditions grouping by code to collect duplicates in one place
          allowedChildrenConditionCodes.forEach((childrenCode) => {
            if (childrenCode in groupedChildrenConditions) {
              allowedToothConditions.push(
                ...groupedChildrenConditions[childrenCode],
              );
            } else {
              const emptyChildrenCondition = new Condition({
                Attribution: { case: 'Tooth', value: { ToothID: toothID } },
                Code: childrenCode,
                ParentCode: code,
                ParentID:
                  code in conditions ? conditions[code][0]?.ID : undefined,
              });

              allowedToothConditions.push(emptyChildrenCondition);
            }
          });
        }
      });

      console.log({
        allowedToothConditions: allowedToothConditions.map((t) => t.Code),
      });

      return allowedToothConditions;
    },
  );

export const selectAllowedLogicalConditions = (toothID: string) =>
  createSelector(selectAllowedConditions(toothID), processLogicalConditions);

export const selectCompactToothConditions = (toothID: string) =>
  createSelector(
    conditionModel.selectors.selectToothActiveLogicalConditions(toothID),
    reportsModel.selectors.selectLowProbabilityMode,
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_Probabilities,
    ),
    makeCompactConditions,
  );

export const selectPreviewCompactToothConditions = (toothID: string) =>
  createSelector(
    conditionModel.selectors.selectToothPreviewActiveLogicalConditions(toothID),
    reportsModel.selectors.selectLowProbabilityMode,
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_Probabilities,
    ),
    makeCompactConditions,
  );

export const selectAllowedCompactToothConditions = (toothID: string) =>
  createSelector(
    selectAllowedLogicalConditions(toothID),
    reportsModel.selectors.selectLowProbabilityMode,
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Hide_Probabilities,
    ),
    makeCompactConditions,
  );
