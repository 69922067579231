import { isArray, mergeWith } from 'lodash-es';

import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { Certainty } from '@/shared/api/protocol-ts/model/dto_report_certainty_pb';
import { Decision } from '@/shared/api/protocol-ts/model/dto_report_common_pb';

import { LogicalCondition } from '../config';

function pickPositiveEngineDecision(objValue: Decision, srcValue: Decision) {
  if (
    objValue === Decision.PositiveDecision ||
    srcValue === Decision.PositiveDecision
  ) {
    return Decision.PositiveDecision;
  }

  return objValue;
}

function mergeCustomizer(objValue: unknown, srcValue: unknown) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }

  // NOTE: Logical condition should have highest ModelScore from all merged conditions
  // If some condition has PositiveDecision, LogicalCondition should have PositiveDecision
  if ((objValue as Certainty)?.ModelScore) {
    return new Certainty({
      ...(objValue as Certainty),
      EngineDecision: pickPositiveEngineDecision(
        (objValue as Certainty).EngineDecision,
        (srcValue as Certainty).EngineDecision,
      ),
      ModelScore: Math.max(
        (objValue as Certainty).ModelScore,
        (srcValue as Certainty).ModelScore,
      ),
    });
  }
}

export const makeLogicalCondition = (
  conditions: Condition[],
): LogicalCondition =>
  conditions.reduce((logicalCondition: LogicalCondition, condition) => {
    const currentLogicalCondition = structuredClone(
      condition,
    ) as LogicalCondition;

    currentLogicalCondition.IDs = currentLogicalCondition.ID
      ? [currentLogicalCondition.ID]
      : [];
    currentLogicalCondition.Children = [];

    return mergeWith(
      logicalCondition,
      currentLogicalCondition,
      mergeCustomizer,
    );
  }, {} as LogicalCondition);
