import { MouseEventHandler } from 'react';
import { DropzoneState } from 'react-dropzone';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Icon } from '@/shared/ui';

import styles from './UploadDentalPhotosDropzone.module.scss';

type UploadDentalPhotosDropzoneProps = {
  dropzoneState: DropzoneState;
  canUploadFolder?: boolean;
};

export const UploadDentalPhotosDropzone = (
  props: UploadDentalPhotosDropzoneProps,
) => {
  const {
    dropzoneState: { getRootProps, getInputProps, inputRef },
    canUploadFolder = true,
  } = props;

  const { formatMessage } = useIntl();

  const handleUploadFolder: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    inputRef.current?.click();
  };

  return (
    <div className={styles.container} {...getRootProps()}>
      <div className={styles.buttonsWrapper}>
        <div className={cn(styles.button)}>
          <input {...getInputProps()} />

          <Icon name="file" size={24} />

          <p className={cn(styles.title, 'p2')}>
            {formatMessage({
              id: 'dropzone.uploadFile.title',
              defaultMessage: 'Upload files',
            })}
          </p>
        </div>

        {canUploadFolder && (
          <div className={cn(styles.button)} onClick={handleUploadFolder}>
            <input
              id="uploadFolderInput"
              {...getInputProps()}
              type="file"
              webkitdirectory=""
            />

            <Icon name="folder" size={28} />

            <p className={cn(styles.title, 'p2')}>
              {formatMessage({
                id: 'dropzone.uploadFolder.title',
                defaultMessage: 'Upload folder',
              })}
            </p>
          </div>
        )}
      </div>

      <p className={cn(styles.text, 'p3')}>
        {formatMessage({
          id: 'dropzone.dragAndDrop.text',
          defaultMessage: 'Or drag and drop file or folder here',
        })}
      </p>
    </div>
  );
};
