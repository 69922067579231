import { createSelector } from '@reduxjs/toolkit';

import {
  MASK_FILTERS_ENDO_CODES,
  MASK_FILTERS_PERIO_CODES,
  MASK_FILTERS_RESTORATIVE_CODES,
  MaskFilterParams,
  MaskGroup,
} from '@/shared/config';
import {
  getActiveConditionCodes,
  getMaxFaxLocalizations,
  getToothAnatomyLocalizations,
  processActiveConditionsMasks,
  processMaxFaxAnatomyLocalizations,
  processToothAnatomyLocalizations,
} from '@/shared/embeddedLibs/conditionsAndMasks';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { conditionModel } from '@/entities/condition';
import { toothModel } from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';
import { organizationModel } from '@/entities/organization';

import { RootState } from '@/app/model/store';

export const selectMasksFlags = createSelector(
  organizationModel.selectors.selectFeatureList,
  reportsModel.selectors.selectCurrentReportType,
  (featureFlags, reportType) => {
    return {
      isCBCTMasksHidden:
        reportType === ReportType.ReportType_CBCT_GP &&
        featureFlags &&
        featureFlags[FeatureFlag.FeatureFlag_Hide_AllCBCTMasks],
      isConditionsMasksHidden:
        featureFlags &&
        featureFlags[FeatureFlag.FeatureFlag_Hide_ConditionsMasks],
      isAnatomyMasksHidden:
        featureFlags && featureFlags[FeatureFlag.FeatureFlag_Hide_AnatomyMasks],
    };
  },
);

export const selectFilters = createSelector(
  (state: RootState) => state.maskFilters.filters,
  selectMasksFlags,
  (filters, masksFlags) => {
    const { isCBCTMasksHidden, isConditionsMasksHidden, isAnatomyMasksHidden } =
      masksFlags;

    const maskGroups = Object.entries(filters) as [
      MaskGroup,
      MaskFilterParams,
    ][];

    const filteredMaskGroups = maskGroups.map(
      ([filterGroup, { disabled, isActive }]) => {
        return [
          filterGroup,
          {
            disabled,
            isActive,
            hide:
              !!isCBCTMasksHidden ||
              (!!isConditionsMasksHidden && filterGroup !== 'chart') ||
              (!!isAnatomyMasksHidden && filterGroup === 'chart'),
          },
        ];
      },
    ) as [MaskGroup, MaskFilterParams][];

    return filteredMaskGroups;
  },
);

export const selectMaxFaxLocalizations = createSelector(
  reportsModel.selectors.selectCurrentReport,
  getMaxFaxLocalizations,
);

export const selectMaskActiveConditionCodes = createSelector(
  selectFilters,
  getActiveConditionCodes,
);

export const selectActiveConditionsMasks = createSelector(
  conditionModel.selectors.selectConditionsWithLocalizations,
  selectMaskActiveConditionCodes,
  processActiveConditionsMasks,
);

export const selectToothAnatomies = createSelector(
  reportsModel.selectors.selectCurrentReportID,
  toothModel.selectors.selectAll,
  getToothAnatomyLocalizations,
);

export const selectMaxFaxAnatomy2DMaskData = createSelector(
  selectMaxFaxLocalizations,
  processMaxFaxAnatomyLocalizations,
);

export const selectToothAnatomy2DMaskData = createSelector(
  selectToothAnatomies,
  processToothAnatomyLocalizations,
);

export const selectReportActiveMasks = createSelector(
  selectActiveConditionsMasks,
  selectToothAnatomy2DMaskData,
  selectMaxFaxAnatomy2DMaskData,
  selectMasksFlags,
  (
    masks2DConfig,
    toothAnatomyMaskData,
    maxFaxAnatomy2DMaskData,
    masksFlags,
  ) => {
    const { isConditionsMasksHidden, isAnatomyMasksHidden, isCBCTMasksHidden } =
      masksFlags;

    const filteredConditionsMasksByAnatomyFlag = isAnatomyMasksHidden
      ? masks2DConfig.filter((mask) => mask.groupID !== 'chart')
      : masks2DConfig;

    const filteredConditionsMasksData =
      isConditionsMasksHidden || isCBCTMasksHidden
        ? []
        : [...filteredConditionsMasksByAnatomyFlag];

    const filteredToothAnatomyMaskData =
      isAnatomyMasksHidden || isCBCTMasksHidden
        ? []
        : [...toothAnatomyMaskData];

    const filteredMaxFaxAnatomy2DMaskData =
      isAnatomyMasksHidden || isCBCTMasksHidden
        ? []
        : [...maxFaxAnatomy2DMaskData];

    const allMasks = [
      ...filteredConditionsMasksData,
      ...filteredToothAnatomyMaskData,
      ...filteredMaxFaxAnatomy2DMaskData,
    ];

    return allMasks;
  },
);

export const selectMaskFilterDisabled = (maskFilterType: MaskGroup) =>
  createSelector(
    conditionModel.selectors.selectConditionCodesWithMasks,
    reportsModel.selectors.selectCurrentReport,
    (conditionCodesWithMasks, currentReport) => {
      switch (maskFilterType) {
        case 'restorative': {
          return !conditionCodesWithMasks.some((code) =>
            MASK_FILTERS_RESTORATIVE_CODES.includes(code),
          );
        }
        case 'endo': {
          return !conditionCodesWithMasks.some((code) =>
            MASK_FILTERS_ENDO_CODES.includes(code),
          );
        }
        // Perio should never be disabled, but temporarily in CBCT we disable this button if there are no masks
        case 'perio': {
          return (
            currentReport?.Type === ReportType.ReportType_CBCT_GP &&
            !conditionCodesWithMasks.some((code) =>
              MASK_FILTERS_PERIO_CODES.includes(code),
            )
          );
        }
        default: {
          return false;
        }
      }
    },
  );

export const selectActiveFilters = createSelector(selectFilters, (allFilters) =>
  allFilters
    .reduce(
      (filtersAccumulator, [filterGroup, { isActive }]) =>
        isActive ? filtersAccumulator.concat(filterGroup) : filtersAccumulator,
      [] as MaskGroup[],
    )
    .sort(),
);

export const selectFiltersIDs = (state: RootState) =>
  state.maskFilters.filterIDs;

export const selectIsLandmarksShown = (state: RootState) =>
  state.maskFilters.isLandmarksShown;
