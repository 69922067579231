import { createSelector } from '@reduxjs/toolkit';

// TODO: broken FSD imports, need to refactoring
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { Asset, AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import {
  Report_2D_Image_Meta,
  Report_2D_ImageType,
} from '@/shared/api/protocol-ts/model/dto_report_type_2D_common_pb';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { assetsModel, getImageSrc } from '@/entities/assets';

import { IOXrayImageInterface, groupIOXRayImagesByPartition } from '..';

const getOriginImageData = (asset: Asset) => {
  if (
    asset.Kind.case === 'GeneratedReport' &&
    asset.Kind.value.Content.case === 'IOXRayImage' &&
    asset.Kind.value.Content.value.Image?.Metadata.case === 'Image'
  ) {
    return asset.Kind.value.Content.value.Image.Metadata.value;
  }

  if (asset.Kind.case === 'Study') {
    if (
      asset.Kind.value.Content.case === 'IntraoralXRay' &&
      asset.Kind.value.Content.value.Image?.Metadata.case === 'Image'
    ) {
      return asset.Kind.value.Content.value.Image?.Metadata.value;
    }
    if (
      asset.Kind.value.Content.case === 'PanoramicXRay' &&
      asset.Kind.value.Content.value.PanoramaImage?.Metadata.case === 'Image'
    ) {
      return asset.Kind.value.Content.value.PanoramaImage.Metadata.value;
    }
  }
};

const getPreviewImageData = (asset: Asset) => {
  if (
    asset.Kind.case === 'GeneratedReport' &&
    asset.Kind.value.Content.case === 'IOXRayImage' &&
    asset.Kind.value.Content.value.Derived?.Preview?.Metadata.case === 'Image'
  ) {
    return asset.Kind.value.Content.value.Derived.Preview.Metadata.value;
  }

  if (asset.Kind.case === 'Study') {
    if (
      asset.Kind.value.Content.case === 'IntraoralXRay' &&
      asset.Kind.value.Content.value.Derived?.Preview?.Metadata.case === 'Image'
    ) {
      return asset.Kind.value.Content.value.Derived.Preview.Metadata.value;
    }
    if (
      asset.Kind.value.Content.case === 'PanoramicXRay' &&
      asset.Kind.value.Content.value.Derived?.Preview?.Metadata.case === 'Image'
    ) {
      return asset.Kind.value.Content.value.Derived.Preview.Metadata.value;
    }
  }
};

const getThumbnailImageData = (asset: Asset) => {
  if (
    asset.Kind.case === 'GeneratedReport' &&
    asset.Kind.value.Content.case === 'IOXRayImage' &&
    asset.Kind.value.Content.value.Derived?.Thumbnail?.Metadata.case === 'Image'
  ) {
    return asset.Kind.value.Content.value.Derived.Thumbnail.Metadata.value;
  }

  if (asset.Kind.case === 'Study') {
    if (
      asset.Kind.value.Content.case === 'IntraoralXRay' &&
      asset.Kind.value.Content.value.Derived?.Thumbnail?.Metadata.case ===
        'Image'
    ) {
      return asset.Kind.value.Content.value.Derived.Thumbnail.Metadata.value;
    }
    if (
      asset.Kind.value.Content.case === 'PanoramicXRay' &&
      asset.Kind.value.Content.value.Derived?.Thumbnail?.Metadata.case ===
        'Image'
    ) {
      return asset.Kind.value.Content.value.Derived.Thumbnail.Metadata.value;
    }
  }
};

const selectAllImageInterfaceByReportID = (reportID: string) =>
  createSelector(
    toothModel.selectors.selectByReportID(reportID),
    reportsModel.selectors.selectByID(reportID),
    assetsModel.selectors.selectEntities,
    (teeth, report, assetEntities) => {
      let imagesMeta: Report_2D_Image_Meta[] = [];

      switch (report?.Specific.case) {
        case 'DataIOXRayGP': {
          imagesMeta = report.Specific.value.ImagesMeta;
          break;
        }
        case 'DataPanoBitewings': {
          imagesMeta = report.Specific.value.ImagesMeta;
          break;
        }
        case 'DataPanoGP': {
          imagesMeta = report.Specific.value.ImagesMeta;
          break;
        }
      }

      const IOXRayImagesInterface = imagesMeta.reduce((acc, imageMeta) => {
        const assetID = imageMeta.GeneratedAssetID || imageMeta.StudyAssetID;

        const asset = assetEntities[assetID];

        if (!asset) {
          return acc;
        }

        const originalImage = getOriginImageData(asset);
        const previewImage = getPreviewImageData(asset);
        const thumbnailImage = getThumbnailImageData(asset);

        const filteredTeeth = teeth.filter((tooth) =>
          tooth?.Detections?.find(
            (detection) => detection.Localizations?.TargetAssetID === assetID,
          ),
        );

        const hasToothWithDuplicates = filteredTeeth.some((tooth) => {
          const targetAssetIDs = tooth.Detections.map(
            (detection) => detection.Localizations?.TargetAssetID,
          );
          return targetAssetIDs.length !== new Set(targetAssetIDs).size;
        });

        const imageInterface = {
          imageMeta,
          asset,
          teethIDs: filteredTeeth.map((tooth) => tooth.ID),
          teethISONumbers: filteredTeeth.map(
            (tooth) => tooth.Numeration?.ISO as number,
          ),
          hasDuplicates: hasToothWithDuplicates,
          originalSize: {
            width: Number(originalImage?.Width || 0),
            height: Number(originalImage?.Height || 0),
          },
          width: Number(originalImage?.Width || 0),
          height: Number(originalImage?.Height || 0),
          previewSize: {
            width: Number(previewImage?.Width || 0),
            height: Number(previewImage?.Height || 0),
          },
          thumbnailSize: {
            width: Number(thumbnailImage?.Width || 0),
            height: Number(thumbnailImage?.Height || 0),
          },
        };

        acc.push(imageInterface);

        return acc;
      }, [] as IOXrayImageInterface[]);

      return IOXRayImagesInterface;
    },
  );

export const selectIOXRayImagesInterfaceByReportID = (reportID: string) =>
  createSelector(selectAllImageInterfaceByReportID(reportID), (imageItems) => {
    const result = imageItems.filter(
      (imageItem) =>
        imageItem.asset.Type ===
          AssetType.AssetType_Report_Generated_IOXRayImage ||
        imageItem.asset.Type === AssetType.AssetType_Study_IntraoralXRay,
    );

    return result;
  });

export const selectPanoImageInterfaceByReportID = (reportID: string) =>
  createSelector(selectAllImageInterfaceByReportID(reportID), (imageItems) =>
    imageItems.find(
      (imageItem) =>
        imageItem.asset.Type === AssetType.AssetType_Study_PanoramicXRay,
    ),
  );

export const selectTeethIDsForFocusedImage = (reportID: string) =>
  createSelector(
    selectIOXRayImagesInterfaceByReportID(reportID),
    selectPanoImageInterfaceByReportID(reportID),
    reportsModel.selectors.selectFocusedImageMetaID,
    toothModel.selectors.selectTeethIDsToShow(reportID),
    (
      IOXRayImagesInterface,
      panoImageInterface,
      focusedImageMetaID,
      teethIDsToShow,
    ) => {
      const focusedImageInterface = [
        panoImageInterface,
        ...IOXRayImagesInterface,
      ].find(
        (imageItem) =>
          imageItem?.imageMeta.GeneratedAssetID === focusedImageMetaID ||
          imageItem?.imageMeta.StudyAssetID === focusedImageMetaID,
      );

      const teethIDsForIOXRayCrop = focusedImageMetaID
        ? teethIDsToShow.filter((id) =>
            focusedImageInterface?.teethIDs.includes(id),
          )
        : teethIDsToShow;

      return teethIDsForIOXRayCrop;
    },
  );

export const selectIsSingleImageItem = (reportID: string) =>
  createSelector(
    selectAllImageInterfaceByReportID(reportID),
    (imageItems) => imageItems.length === 1,
  );

export const selectIOXRayPreviewImages = (reportID: string) =>
  createSelector(
    reportsModel.selectors.selectByID(reportID),
    toothModel.selectors.selectByReportID(reportID),
    (report, teeth) => {
      let imagesMeta: Report_2D_Image_Meta[] = [];

      switch (report?.Specific.case) {
        case 'DataIOXRayGP': {
          imagesMeta = report.Specific.value.ImagesMeta;
          break;
        }
        case 'DataPanoBitewings': {
          imagesMeta = report.Specific.value.ImagesMeta;
          break;
        }
      }

      const previewImages = imagesMeta.reduce(
        (previewImagesAcc, imageMeta) => {
          const assetID = imageMeta.GeneratedAssetID || imageMeta.StudyAssetID;

          if (
            imageMeta.Type ===
            Report_2D_ImageType.Report_2D_ImageType_IntraoralXRay
          ) {
            previewImagesAcc.push({
              src: getImageSrc(assetID, 'thumbnail'),
              teethISONumbers: teeth
                .filter((tooth) =>
                  tooth?.Detections?.find(
                    (detection) =>
                      detection.Localizations?.TargetAssetID === assetID,
                  ),
                )
                .map((tooth) => tooth.Numeration?.ISO as number),
            });
          }
          return previewImagesAcc;
        },
        [] as { src: string; teethISONumbers: number[] }[],
      );

      // @ts-expect-error WARN: groupIOXRayImagesByPartition should use generic to accept different data structures
      const groupedPreviewImages = groupIOXRayImagesByPartition(previewImages);

      switch (report?.Type) {
        case ReportType.ReportType_Pano_Bitewings: {
          return groupedPreviewImages.MiddleLeft.concat(
            groupedPreviewImages.MiddleRight,
          );
        }
        case ReportType.ReportType_IOXRay_GP:
        default: {
          return groupedPreviewImages;
        }
      }
    },
  );
