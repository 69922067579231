import { generatePath } from 'react-router';

export const generatePathWithTimezone = (
  path: string,
  params: Record<string, string>,
  timezone: string,
) => {
  const baseUrl = generatePath(path, params);
  return `${baseUrl}${baseUrl.includes('?') ? '&' : '?'}timezone=${encodeURIComponent(timezone)}`;
};
