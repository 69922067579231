import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';

import { PATHS } from '@/shared/config';
import preview from '@/shared/assets/images/DentalPhotosPreview.png';

import styles from './DentalPhotoReportCard.module.scss';

type DentalPhotoReportCardProps = {
  patientID: string;
  className?: string;
  testID?: string;
};

export const DentalPhotoReportCard: FC<DentalPhotoReportCardProps> = (
  props,
) => {
  const { className, testID, patientID } = props;

  const { formatMessage } = useIntl();

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <h4 className={cn(styles.title, 'h4')}>
        {formatMessage({
          id: 'studyUpload.DentalPhotos',
          defaultMessage: 'Dental Photos',
        })}
      </h4>

      <Link
        className={styles.innerContainer}
        to={generatePath(PATHS.dentalPhotos, {
          patientID,
        })}
      >
        <img
          className={styles.overlay}
          alt={formatMessage({
            id: 'dentalPhotos.widget.preview.altText',
            defaultMessage: 'Dental photo preview',
          })}
          src={preview}
        />
      </Link>
    </div>
  );
};
