import { useCallback } from 'react';
import { isEmpty } from 'ramda';
import { useParams } from 'react-router';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { scrollToElementByID } from '@/shared/lib';
import { Skeleton } from '@/shared/ui';
import { MedicalImageRender } from '@/shared/graphics/medicalImageRender/MedicalImageRender';

import { toothModel } from '@/entities/tooth';
import { FMXNavigationMode, reportsModel } from '@/entities/reports';

import {
  ToothChart,
  toothChartModel,
  ToothChartItemClickHandler,
} from '@/features/toothChart';
import { Report2DSettingsBar } from '@/features/report2DSettingsBar';

import styles from './Report2DToothChart.module.scss';

export const Report2DToothChart = () => {
  const { reportID } = useParams();
  const dispatch = useAppDispatch();

  const { toothItems, toothTypesSet } = useAppSelector(
    toothChartModel.selectors.selectToothChartItems(reportID as string),
  );
  const customMode = useAppSelector(
    toothModel.selectors.selectToothChartCustomMode,
  );

  const isToothChartReady = !isEmpty(toothTypesSet);

  const handleToothClick = useCallback<ToothChartItemClickHandler>(
    ({ id, active }) => {
      if (customMode) {
        dispatch(toothModel.actions.toggleLocalROITeethIDs([id]));
      } else if (active) {
        dispatch(reportsModel.actions.setFocusedMetaImageID(''));
        dispatch(
          reportsModel.actions.setNavigationMode(FMXNavigationMode.ToothChart),
        );

        // NOTE: It needs to properly scroll to the tooth card which appears in the DOM later than this function executes if it's just a sync call.
        setTimeout(() => scrollToElementByID(id, 'smooth', 'start'), 0);

        dispatch(reportsModel.actions.setActiveToothID(id));

        MedicalImageRender.layoutModes.crop(id);
      }
    },
    [customMode, dispatch],
  );

  return (
    <ToothChart.Root size="small" className={styles.container}>
      <ToothChart.Header hideTitle>
        <Report2DSettingsBar className={styles.reportSettingsBar} />
      </ToothChart.Header>

      {isToothChartReady ? (
        <>
          <ToothChart.Tags
            toothTypesSet={toothTypesSet}
            reportID={reportID as string}
          >
            <ToothChart.CustomModeToggle
              buttonSize="tiny"
              className={styles.customModeToggle}
            />
          </ToothChart.Tags>

          <ToothChart.Grid>
            {toothItems.map((toothItem) => (
              <ToothChart.MetaItem
                key={toothItem.id}
                toothItem={toothItem}
                handleToothClick={handleToothClick}
              />
            ))}
          </ToothChart.Grid>
        </>
      ) : (
        <>
          <Skeleton.Filter
            filtersQuantity={4}
            height={20}
            gap={4}
            borderRadius={10}
          />

          <Skeleton width={484} height={126} borderRadius={4} />
        </>
      )}
    </ToothChart.Root>
  );
};
