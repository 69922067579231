// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/core/svc_album.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { DentalPhotoAlbum } from "../../model/dto_dental_photo_album_pb.js";

/**
 * @generated from message com.diagnocat.core.CreateAlbumReq
 */
export const CreateAlbumReq = proto3.makeMessageType(
  "com.diagnocat.core.CreateAlbumReq",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "StudyIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "Name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Time", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message com.diagnocat.core.CreateAlbumResp
 */
export const CreateAlbumResp = proto3.makeMessageType(
  "com.diagnocat.core.CreateAlbumResp",
  () => [
    { no: 1, name: "DentalPhotoAlbum", kind: "message", T: DentalPhotoAlbum },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetMainInfoReq
 */
export const SetMainInfoReq = proto3.makeMessageType(
  "com.diagnocat.core.SetMainInfoReq",
  () => [
    { no: 1, name: "AlbumID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Time", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetMainInfoResp
 */
export const SetMainInfoResp = proto3.makeMessageType(
  "com.diagnocat.core.SetMainInfoResp",
  () => [
    { no: 1, name: "DentalPhotoAlbum", kind: "message", T: DentalPhotoAlbum },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteAlbumReq
 */
export const DeleteAlbumReq = proto3.makeMessageType(
  "com.diagnocat.core.DeleteAlbumReq",
  () => [
    { no: 1, name: "AlbumID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteAlbumResp
 */
export const DeleteAlbumResp = proto3.makeMessageType(
  "com.diagnocat.core.DeleteAlbumResp",
  () => [
    { no: 1, name: "DeletedAlbumID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "DeletedStudyIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteStudiesFromAlbumReq
 */
export const DeleteStudiesFromAlbumReq = proto3.makeMessageType(
  "com.diagnocat.core.DeleteStudiesFromAlbumReq",
  () => [
    { no: 1, name: "AlbumID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "StudyIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteStudiesFromAlbumResp
 */
export const DeleteStudiesFromAlbumResp = proto3.makeMessageType(
  "com.diagnocat.core.DeleteStudiesFromAlbumResp",
  () => [
    { no: 1, name: "DentalPhotoAlbum", kind: "message", T: DentalPhotoAlbum },
    { no: 2, name: "DeletedStudyIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

