export const getEditorContentFromValue = async (value: string) => {
  const { ContentState, convertFromRaw } = await import('draft-js');

  if (value) {
    try {
      const parsedValue = JSON.parse(value);

      return convertFromRaw(parsedValue);
    } catch {
      return ContentState.createFromText(value);
    }
  } else {
    return ContentState.createFromText('');
  }
};
