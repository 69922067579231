import { FileWithPath } from 'react-dropzone';

import { StartUploadSessionReq } from '@/shared/api/protocol-ts/api/core/svc_upload_pb';
import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';

export const getStudyRequestParams = ({
  patientID,
  userID,
  organizationID,
  files,
  assetType,
  toothID,
}: {
  files: FileWithPath[];
  assetType: AssetType;
  patientID?: string;
  userID?: string;
  organizationID?: string;
  toothID?: string;
  /**
   * @deprecated
   */
  studyID?: string;
}): StartUploadSessionReq => {
  const filePaths = files.map((file) => file.path ?? '');

  switch (assetType) {
    case AssetType.AssetType_Organization_EmployeeStamp: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'OrganizationEmployeeStamp',
          value: {
            UserID: userID,
            OrganizationID: organizationID,
            FilePath: filePaths[0],
          },
        },
      });
    }
    case AssetType.AssetType_Organization_Stamp: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'OrganizationStamp',
          value: {
            OrganizationID: organizationID,
            FilePath: filePaths[0],
          },
        },
      });
    }
    case AssetType.AssetType_User_Signature: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'UserSignature',
          value: {
            UserID: userID,
            FilePath: filePaths[0],
          },
        },
      });
    }
    case AssetType.AssetType_User_Avatar: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'UserAvatar',
          value: {
            UserID: userID,
            FilePath: filePaths[0],
          },
        },
      });
    }
    case AssetType.AssetType_Organization_Logo: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'OrganizationLogo',
          value: {
            OrganizationID: organizationID,
            FilePath: filePaths[0],
          },
        },
      });
    }
    case AssetType.AssetType_Report_Uploaded_ToothSlice: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'ToothUserUploadedSlice',
          value: {
            ToothID: toothID,
            Slices: files.map((file) => ({
              Slice: {
                Path: file.path,
                MIME: file.type,
                Size: BigInt(file.size),
                NoMetadata: {},
              },
            })),
          },
        },
      });
    }
    case AssetType.AssetType_Study_CBCT: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'StudyCBCT',
          value: {
            Target: {
              case: 'PatientID',
              value: patientID as string,
            },
            FilePaths: filePaths,
          },
        },
      });
    }
    case AssetType.AssetType_Study_IntraoralXRay: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'StudyIntraoralXRay',
          value: {
            Target: {
              case: 'PatientID',
              value: patientID as string,
            },
            FilePaths: filePaths,
          },
        },
      });
    }
    case AssetType.AssetType_Study_PanoramicXRay: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'StudyPanoramicXRay',
          value: {
            Target: {
              case: 'PatientID',
              value: patientID as string,
            },
            FilePath: filePaths[0],
          },
        },
      });
    }
    case AssetType.AssetType_Study_IOS_Meshes: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'StudyIOSMeshes',
          value: {
            Target: {
              case: 'PatientID',
              value: patientID as string,
            },
            FilePaths: filePaths,
          },
        },
      });
    }
    case AssetType.AssetType_Report_Uploaded_MPRPlane: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'ToothUserUploadedMPRPlanes',
          value: {
            ToothID: toothID,
            MPRPanes: files.map((file) => ({
              MPRPlane: {
                MIME: file.type,
                Size: BigInt(file.size),
                NoMetadata: {},
              },
            })),
          },
        },
      });
    }
    case AssetType.AssetType_Study_DentalPhoto: {
      return new StartUploadSessionReq({
        Upload: {
          case: 'StudyDentalPhoto',
          value: {
            Target: {
              case: 'PatientID',
              value: patientID as string,
            },
            FilePaths: filePaths,
          },
        },
      });
    }
    default:
      throw new Error('Asset type is invalid or missing');
  }
};
