import { useLocation } from 'react-router';

export const useUserTimezone = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const timezoneFromUrl = searchParams.get('timezone');
  const userTimezone =
    timezoneFromUrl || Intl.DateTimeFormat().resolvedOptions().timeZone;

  return userTimezone;
};
