import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { sortBy } from 'lodash-es';

import { SelectOptionType } from '@/shared/config';

import { CLUSTERS } from '../config';

type CountryConfig = {
  names: Record<string, string>;
  sign_up: boolean;
};

type Countries = Record<string, CountryConfig>;

export const getClusterPrefix = () => window.origin.split('.').pop();

type UseCountries = {
  // Control from 'react-hook-form'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  fieldName?: string;
};

const getCountries = async (): Promise<Countries> =>
  import('../../../shared/localizations/countries.json').then(
    (countries) => countries.default,
  );

const transformCountries = (countries: Countries, languageKey = 'en-US') =>
  sortBy(
    Object.keys(countries).map((countryKey) => {
      const countryName = countries[countryKey].names[languageKey];
      return {
        value: countryKey,
        label: countryName,
      };
    }),
    'label',
  );

export const useCountries = ({
  control,
  fieldName = 'language',
}: UseCountries) => {
  const [countries, setCountries] = useState<SelectOptionType[]>([]);

  const cluster = getClusterPrefix();

  const language = useWatch({
    control,
    name: fieldName,
  });

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const allCountries = await getCountries();

        if (cluster && cluster in CLUSTERS) {
          setCountries(
            transformCountries(allCountries, language).filter(
              (country: SelectOptionType) =>
                CLUSTERS[cluster].includes(country.value),
            ),
          );
        } else {
          setCountries(transformCountries(allCountries, language));
        }
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    fetchCountries();
  }, [cluster, language]);

  return countries;
};

export const useCountriesOptions = (locale: string): SelectOptionType[] => {
  const [countries, setCountries] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const countries = await getCountries();

        setCountries(transformCountries(countries, locale));
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    fetchCountries();
  }, [locale]);

  return countries;
};
