import { ConnectError } from '@bufbuild/connect';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AddToothDisplaySliceReq,
  AddToothDisplaySliceResp,
  ApproveReportToothReq,
  ApproveReportToothResp,
  CreateReportToothConditionsReq,
  CreateReportToothConditionsResp,
  DeleteReportReq,
  DeleteReportResp,
  DisapproveReportToothReq,
  DisapproveReportToothResp,
  MarkToothAsDeletedReq,
  MarkToothAsDeletedResp,
  MarkToothDetectionAsDeletedReq,
  MarkToothDetectionAsDeletedResp,
  RemoveToothDisplaySliceReq,
  RemoveToothDisplaySliceResp,
  RequestReportReq,
  RequestReportResp,
  ResetReportToothReq,
  ResetReportToothResp,
  SetImageMetaViewOptionsReq,
  SetImageMetaViewOptionsResp,
  SetReportConclusionReq,
  SetReportConclusionResp,
  SetReportMedicalImageViewOptionsReq,
  SetReportMedicalImageViewOptionsResp,
  SetReportPrintSettingsReq,
  SetReportPrintSettingsResp,
  SetReportSettingsReq,
  SetReportSettingsResp,
  SetReportToothCommentReq,
  SetReportToothCommentResp,
  SetReportToothConditionsUserDecisionReq,
  SetReportToothConditionsUserDecisionResp,
  SetReportToothConditionUserScoreAndApproveToothReq,
  SetReportToothConditionUserScoreAndApproveToothResp,
  SetROIReq,
  SetROIResp,
  SetToothDetectionNumerationAndReviveReq,
  SetToothDetectionNumerationAndReviveResp,
  SetToothNumerationAndReviveReq,
  SetToothNumerationAndReviveResp,
  SignReportReq,
  SignReportResp,
} from '@/shared/api/protocol-ts/api/core/svc_report_pb';
import api from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import { ReportService } from '@/shared/api/protocol-ts/api/core/svc_report_connectweb.ts';
import { conditionIsUncertain } from '@/shared/embeddedLibs/conditionsAndMasks';

// TODO: [4/h] Discuss the possibility of creating an additional 'services' layer in the FSD between entities and features
import { ModalID, modalModel } from '@/entities/modal';
import { toothModel } from '@/entities/tooth';

import type { RootState } from '@/app/model/store';

import { CheckReportSignatureModalText } from '../config';
import * as reportsModel from '../model';

export type ReportRequests = keyof typeof ReportService.methods;

const generateReportThunk = createThunkGenerator<ReportRequests>(
  SliceName.reports,
);

export const setReportToothConditionUserScoreAndApproveTooth =
  generateReportThunk<
    SetReportToothConditionUserScoreAndApproveToothReq,
    SetReportToothConditionUserScoreAndApproveToothResp
  >(
    'setReportToothConditionUserScoreAndApproveTooth',
    api.report.setReportToothConditionUserScoreAndApproveTooth,
  );

export const setReportToothConditionsUserDecision = generateReportThunk<
  SetReportToothConditionsUserDecisionReq,
  SetReportToothConditionsUserDecisionResp
>(
  'setReportToothConditionsUserDecision',
  api.report.setReportToothConditionsUserDecision,
);

export const createReportToothConditions = generateReportThunk<
  CreateReportToothConditionsReq,
  CreateReportToothConditionsResp
>('createReportToothConditions', api.report.createReportToothConditions);

export const setReportSettings = generateReportThunk<
  SetReportSettingsReq,
  SetReportSettingsResp
>('setReportSettings', api.report.setReportSettings);

export const requestReport = generateReportThunk<
  RequestReportReq,
  RequestReportResp
>('requestReport', api.report.requestReport);

export const deleteReport_DEPRECATED = generateReportThunk<
  DeleteReportReq,
  DeleteReportResp
>('deleteReport', api.report.deleteReport);

export const setROI = generateReportThunk<SetROIReq, SetROIResp>(
  'setROI',
  api.report.setROI,
);

export const setReportConclusion = generateReportThunk<
  SetReportConclusionReq,
  SetReportConclusionResp
>('setReportConclusion', api.report.setReportConclusion);

export const setReportPrintSettings = generateReportThunk<
  SetReportPrintSettingsReq,
  SetReportPrintSettingsResp
>('setReportPrintSettings', api.report.setReportPrintSettings);

export const signReport = generateReportThunk<SignReportReq, SignReportResp>(
  'signReport',
  api.report.signReport,
);

export const setReportToothComment = generateReportThunk<
  SetReportToothCommentReq,
  SetReportToothCommentResp
>('setReportToothComment', api.report.setReportToothComment);

// WARNING: DO NOT USE THIS METHOD IN RELEASE 1.0.0
export const setReportToothNumeration = generateReportThunk<
  SetToothNumerationAndReviveReq,
  SetToothNumerationAndReviveResp
>('setToothNumerationAndRevive', api.report.setToothNumerationAndRevive);

export const resetReportTooth = generateReportThunk<
  ResetReportToothReq,
  ResetReportToothResp
>('resetReportTooth', api.report.resetReportTooth);

export const setToothNumerationAndRevive = generateReportThunk<
  SetToothNumerationAndReviveReq,
  SetToothNumerationAndReviveResp
>('setToothNumerationAndRevive', api.report.setToothNumerationAndRevive);

export const setToothDetectionsNumerationAndRevive = generateReportThunk<
  SetToothDetectionNumerationAndReviveReq,
  SetToothDetectionNumerationAndReviveResp
>(
  'setToothDetectionNumerationAndRevive',
  api.report.setToothDetectionNumerationAndRevive,
);

// TODO: (4|h) Make feature to avoid enitites collision (break FSD rules) here
export const approveAllTeethAndSignReport = createAsyncThunk(
  `${SliceName.reports}/approveAllTeethAndSignReport`,
  async (reportID: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const teethConditionIDs = state.condition.ids.filter((id) =>
      conditionIsUncertain(state.condition.entities[id]?.Certainty),
    ) as string[];

    const request = {
      ReportID: reportID,
      TeethConditionIDs: teethConditionIDs,
    };

    try {
      const response = await api.report.approveAllTeethAndSignReport(request);

      return response;
    } catch (error) {
      const connectError = ConnectError.from(error);

      return rejectWithValue(connectError);
    }
  },
);

export const setReportToothApproved = generateReportThunk<
  ApproveReportToothReq,
  ApproveReportToothResp
>('approveReportTooth', api.report.approveReportTooth);

export const setReportToothDisapproved = generateReportThunk<
  DisapproveReportToothReq,
  DisapproveReportToothResp
>('disapproveReportTooth', api.report.disapproveReportTooth);

export const addToothDisplaySlice = generateReportThunk<
  AddToothDisplaySliceReq,
  AddToothDisplaySliceResp
>('addToothDisplaySlice', api.report.addToothDisplaySlice);

export const removeToothDisplaySlice = generateReportThunk<
  RemoveToothDisplaySliceReq,
  RemoveToothDisplaySliceResp
>('removeToothDisplaySlice', api.report.removeToothDisplaySlice);

export const setImageMetaViewOptions = generateReportThunk<
  SetImageMetaViewOptionsReq,
  SetImageMetaViewOptionsResp
>('setImageMetaViewOptions', api.report.setImageMetaViewOptions);

export const setReportMedicalImageViewOptions = generateReportThunk<
  SetReportMedicalImageViewOptionsReq,
  SetReportMedicalImageViewOptionsResp
>(
  'setReportMedicalImageViewOptions',
  api.report.setReportMedicalImageViewOptions,
);

export const checkReportSignatureThunk = createAsyncThunk(
  `${SliceName.reports}/checkReportSignature`,
  async (
    options: {
      modalText: CheckReportSignatureModalText;
      toothID?: string;
      onSignatureChecked: () => Promise<void>;
      onCancel?: () => void;
    },
    { dispatch, getState },
  ) => {
    const { toothID, modalText, onSignatureChecked, onCancel } = options;

    const state = getState() as RootState;
    const isReportSigned =
      state.reports.currentReport?.Signature?.UserSignatures.length;

    if (!isReportSigned) {
      await onSignatureChecked();
    } else {
      // TODO: [1/m] remove disapproveTooth when backend adds functionality for automatic disapproval
      const disapproveTooth = async (toothID: string) => {
        const { Tooth } = await dispatch(
          setReportToothDisapproved({
            ToothID: toothID,
          }),
        ).unwrap();

        if (Tooth) {
          dispatch(toothModel.actions.setNewestOne(Tooth));
        }
      };

      const openConfirmModal = async (onConfirm: () => Promise<void>) => {
        dispatch(
          modalModel.actions.openModal({
            modalID: ModalID.Confirm,
            data: {
              title: modalText.title,
              okText: modalText.confirm,
              description: modalText.description,
              bodyClassName: 'p2',
              cancelButtonVariant: 'tertiary',
              onConfirm: async () => {
                dispatch(modalModel.actions.closeModal(ModalID.Confirm));

                await onConfirm();
              },
              onCancel,
            },
          }),
        );
      };

      const checkDisapproveAndContinue = async () => {
        if (toothID) {
          await disapproveTooth(toothID);
        }
        await onSignatureChecked();
      };

      await openConfirmModal(checkDisapproveAndContinue);
    }
  },
);

export const deleteReport = createAsyncThunk(
  `${SliceName.reports}/DeleteReport`,
  async (reportID: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.report.deleteReport({
        ReportID: reportID as string,
      });
      dispatch(reportsModel.actions.removeOne(response.DeletedReportID));

      return response;
    } catch (error) {
      const connectError = ConnectError.from(error);

      return rejectWithValue(connectError);
    }
  },
);

export const markToothAsDeleted = generateReportThunk<
  MarkToothAsDeletedReq,
  MarkToothAsDeletedResp
>('markToothAsDeleted', api.report.markToothAsDeleted);

export const markToothDetectionAsDeleted = generateReportThunk<
  MarkToothDetectionAsDeletedReq,
  MarkToothDetectionAsDeletedResp
>('markToothDetectionAsDeleted', api.report.markToothDetectionAsDeleted);
