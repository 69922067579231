import { FC } from 'react';
import cn from 'classnames';

import { TreatingDoctors } from './TreatingDoctors/TreatingDoctors';
import { SharedWith } from './SharedWith/SharedWith';
import styles from './PatientInfo.module.scss';
import { PatientHistoricalData } from './PatientHistoricalData/PatientHistoricalData';

type PatientInfoProps = {
  className?: string;
  patientID: string;
};

export const PatientInfo: FC<PatientInfoProps> = (props) => {
  const { className, patientID } = props;

  return (
    <div className={styles.container}>
      <div className={cn(styles.mainContent, className)}>
        <TreatingDoctors patientID={patientID} />

        <SharedWith patientID={patientID} />
      </div>

      <PatientHistoricalData patientID={patientID} />
    </div>
  );
};
