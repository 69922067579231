import { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEqual } from 'lodash-es';

import { Button, Description, Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import {
  Localization,
  LocalizationType,
} from '@/shared/api/protocol-ts/model/dto_report_localization_pb';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCDetectedTeeth } from '@/shared/graphics/RenderComponents/RCDetectedTeeth/RCDetectedTeeth';
import { ToothNumeration } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { getName } from '@/shared/lib';

import { ModalID, modalModel } from '@/entities/modal';
import { patientModel } from '@/entities/patient';
import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import { studyModel } from '@/entities/study';
import { toothModel, ToothWithLocalization } from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';
import { userModel } from '@/entities/user';

import { PanoImage } from '@/widgets/PanoImage';

import { ChangeToothNumberPopup } from '../ChangeToothNumberPopup/ChangeToothNumberPopup';
import { makeDetectionsFromTeeth } from '../../lib/makeDetectionsFromTeeth';
import { makeDetectionsFromDeletedToothLocalizations } from '../../lib/makeDetectionsFromLocalizations';
import { TeethNumberingModalProps } from '../../config/types';

import styles from './TeethNumberingModal.module.scss';

type PendingChange = {
  toothID: string;
  oldNumber: number;
  newNumber: number;
  SupernumeraryIndex: number;
};

export const TeethNumberingModal: FC<TeethNumberingModalProps> = ({
  patientID,
  reportID,
  modalWidth,
  reportImage,
}) => {
  const { formatMessage } = useIntl();

  const {
    visible,
    data: { image: modalImage },
  } = useAppSelector(modalModel.selectors.selectTeethNumberingModal);
  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);
  const teeth = useAppSelector(toothModel.selectors.selectByReportID(reportID));
  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );
  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );
  const deletedTeethByReportID = useAppSelector(
    toothModel.selectors.selectDeletedByReportID(reportID),
  );

  const studyID = report?.SourceStudyIDs?.[0];
  const study = useAppSelector(studyModel.selectors.selectByID(studyID ?? ''));

  const [selectedTooth, setSelectedTooth] = useState<
    ToothWithLocalization | undefined
  >(undefined);
  const [isToothRemoving, setIsToothRemoving] = useState(false);
  const [animatedToothNumber, setAnimatedToothNumber] = useState<number>(0);
  const [allTeeth, setAllTeeth] = useState<ToothWithLocalization[]>([]);
  const [pendingChanges, setPendingChanges] = useState<PendingChange[]>([]);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);

  const panoImageContainer = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { checkReportSignature } = useCheckReportSignature();
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const image = reportImage ?? modalImage;
  const patientFullName = getName(
    patient?.PersonalData?.FirstName,
    patient?.PersonalData?.LastName,
    { userLocale },
  );
  const reportType = report?.Type;
  const isPatientLoaded = !!patient;
  const assetID = study?.AssetIDs?.[0];

  const currentTeeth = useMemo(() => {
    return allTeeth.map((tooth) => {
      const change = pendingChanges.find(
        (change) => change.toothID === tooth.toothID,
      );
      if (change) {
        return {
          ...tooth,
          ISONumber: change.newNumber,
        };
      }
      return tooth;
    });
  }, [allTeeth, pendingChanges]);

  const localizations = useMemo(
    () => makeDetectionsFromTeeth(currentTeeth),
    [currentTeeth],
  );

  const deletedTeethLocalizations = useMemo(
    () =>
      makeDetectionsFromDeletedToothLocalizations(deletedTeethByReportID || []),
    [deletedTeethByReportID],
  );

  const hasDuplicates = useMemo(
    () =>
      currentTeeth.some((tooth, index) =>
        currentTeeth.some(
          (otherTooth, otherIndex) =>
            index !== otherIndex &&
            tooth.ISONumber === otherTooth.ISONumber &&
            tooth.SupernumeraryIndex === otherTooth.SupernumeraryIndex,
        ),
      ),
    [currentTeeth],
  );

  useEffect(() => {
    setIsConfirmDisabled(hasDuplicates || pendingChanges.length === 0);
  }, [hasDuplicates, pendingChanges.length]);

  const title = formatMessage({
    id: 'report.EditTeethNumbers',
    defaultMessage: 'Edit teeth numbers',
  });

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.TeethNumberingModal));
  }, [dispatch]);

  const handleToothClick = useCallback((tooth?: ToothWithLocalization) => {
    setSelectedTooth(tooth);
  }, []);

  const handleChangeToothNumber = useCallback(
    (tooth: ToothWithLocalization, newNumber: number) => {
      setAnimatedToothNumber(newNumber);

      const newChange: PendingChange = {
        toothID: tooth.toothID,
        oldNumber: tooth.ISONumber,
        newNumber,
        SupernumeraryIndex: tooth?.SupernumeraryIndex ?? 0,
      };

      setPendingChanges((prevChanges) => {
        const filteredChanges = prevChanges.filter(
          (change) => change.toothID !== tooth.toothID,
        );
        return [...filteredChanges, newChange];
      });

      setSelectedTooth(undefined);
    },
    [],
  );

  const handleConfirmChanges = useCallback(() => {
    checkReportSignature({
      onSignatureChecked: async () => {
        try {
          const changes = pendingChanges.map((change) => {
            const numeration = new ToothNumeration();
            numeration.ISO = change.newNumber;
            numeration.SupernumeraryIndex = change.SupernumeraryIndex;

            return dispatch(
              reportsModel.thunks.setToothNumerationAndRevive({
                ToothID: change.toothID,
                Numeration: numeration,
              }),
            ).unwrap();
          });

          const results = await Promise.all(changes);

          results.forEach(({ Tooth, Report }) => {
            if (Tooth) {
              dispatch(toothModel.actions.setNewestOne(Tooth));
            }
            if (Report) {
              dispatch(reportsModel.actions.setNewestOne(Report));
            }
          });

          setPendingChanges([]);
          setIsConfirmDisabled(true);
          handleClose();
        } catch (error) {
          console.error('Error applying teeth number changes:', error);
        }
      },
    });
  }, [dispatch, pendingChanges, handleClose, checkReportSignature]);

  const handleRemoveTooth = useCallback(
    (tooth: ToothWithLocalization) => {
      checkReportSignature({
        onSignatureChecked: async () => {
          setIsToothRemoving(true);
          try {
            const { Tooth, Report } = await dispatch(
              reportsModel.thunks.markToothAsDeleted({
                ToothID: tooth.toothID,
              }),
            ).unwrap();

            if (Tooth) {
              dispatch(toothModel.actions.setNewestOne(Tooth));
            }
            if (Report) {
              dispatch(reportsModel.actions.setNewestOne(Report));
            }
          } finally {
            setSelectedTooth(undefined);
            setIsToothRemoving(false);
          }
        },
      });
    },
    [dispatch, checkReportSignature],
  );

  useEffect(() => {
    if (teeth?.length) {
      const patientTeeth = teeth.reduce<ToothWithLocalization[]>(
        (modifiedTeeth, tooth: Tooth) => {
          if (
            !tooth?.IsRemoved &&
            tooth?.Detections?.length &&
            tooth.Detections.some(
              (detection) =>
                detection.Localizations?.Type ===
                LocalizationType.LocalizationType2D,
            )
          ) {
            modifiedTeeth.push({
              toothID: tooth?.ID,
              ISONumber: tooth.Numeration?.ISO ?? 0,
              SupernumeraryIndex: tooth.Numeration?.SupernumeraryIndex ?? 0,
              Localization:
                tooth?.Detections.find(
                  (detection) =>
                    detection.Localizations?.Type ===
                    LocalizationType.LocalizationType2D,
                )?.Localizations ?? ({} as Localization),
            });
          }
          return modifiedTeeth;
        },
        [],
      );

      if (!isEqual(patientTeeth, allTeeth)) {
        setAllTeeth(patientTeeth);
      }
    }
  }, [teeth, allTeeth]);

  useEffect(() => {
    if (!visible) {
      setPendingChanges([]);
      setIsConfirmDisabled(true);
      setAnimatedToothNumber(0);
    }
  }, [visible]);

  useEffect(() => {
    if (animatedToothNumber !== 0) {
      const timer = setTimeout(() => {
        setAnimatedToothNumber(0);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [animatedToothNumber]);

  return (
    <Modal
      title={title}
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      containerClassName={styles.modalContainer}
      hideFooter
      borderless
      shouldCloseOnOverlayClick={!isConfirmDisabled}
      shouldRenderCancelButton={false}
      modalInlineStyle={{ width: `${modalWidth}px` }}
    >
      <div className={styles.container}>
        <div className={styles.imageContainer} ref={panoImageContainer}>
          {reportType === ReportType.ReportType_CBCT_GP && (
            <PanoImage
              src={image.src}
              controls={[]}
              kind="dicom"
              objectFit="cover"
              style={{
                width: '100%',
                height: image.height,
                objectFit: 'contain',
              }}
              containerWidth={image.width ?? 0}
              fixedHeight={image.height}
              assetID={assetID}
              viewOptions={image.viewOptions}
            />
          )}

          <AbsoluteBlock style={{ top: 0, left: 0 }}>
            <RCDetectedTeeth
              deletedTeethLocalizations={deletedTeethLocalizations}
              localizations={localizations}
              imageSize={{
                width: (image?.width ?? 0) / (image?.zoom ?? 0),
                height: (image?.height ?? 0) / (image?.zoom ?? 0),
              }}
              onToothClick={handleToothClick}
              dentalNotationFormat={dentalNotationFormat}
            />
          </AbsoluteBlock>

          <ChangeToothNumberPopup
            isOpen={!!selectedTooth}
            onChangeToothNumber={handleChangeToothNumber}
            onChangeSelectedTooth={handleToothClick}
            title={title}
            selectedTooth={selectedTooth}
            isToothRemoving={isToothRemoving}
            dentalNotationFormat={dentalNotationFormat}
            onRemoveTooth={handleRemoveTooth}
            animatedToothNumber={animatedToothNumber}
          />
        </div>
      </div>

      <div className={styles.footer}>
        {isPatientLoaded && (
          <div className={styles.descriptionContainer}>
            <Description
              label={
                <FormattedMessage
                  id="teethNymbering.patient"
                  defaultMessage="Patient"
                />
              }
            >
              {patientFullName}
            </Description>
            {pendingChanges.length > 0 && (
              <Description
                label={
                  <FormattedMessage
                    id="teethNymbering.pendingChanges"
                    defaultMessage="Pending changes"
                  />
                }
              >
                {pendingChanges.length}
              </Description>
            )}
          </div>
        )}

        <Button
          size="medium"
          onClick={handleConfirmChanges}
          className={styles.approveButton}
          disabled={isConfirmDisabled || pendingChanges.length === 0}
        >
          <FormattedMessage id="patient.confirm" defaultMessage="Confirm" />
        </Button>
      </div>
    </Modal>
  );
};
