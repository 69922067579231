import { useIntl } from 'react-intl';
import cn from 'classnames';
import { useMemo, useRef } from 'react';
import { first } from 'lodash-es';

import {
  Study,
  StudyStatus,
  StudyType,
} from '@/shared/api/protocol-ts/model/dto_study_pb';
import { useAppSelector, useMedia } from '@/shared/hooks';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';

import { ensureRefreshImageSrc, getImageSrc } from '@/entities/assets';
import { i18n } from '@/entities/study';
import { reportsModel } from '@/entities/reports';

import { UploadingStatusFrame } from '../UploadingStatusFrame/UploadingStatusFrame';
import { studyCardTitle } from '../../config';

import styles from './StudyPreview.module.scss';

type StudyPreviewProps = {
  study: Study;
};

const ALLOWED_REPORT_TYPES = [
  ReportType.ReportType_CBCT_GP,
  ReportType.ReportType_IOXRay_GP,
  ReportType.ReportType_Pano_GP,
  ReportType.ReportType_Pano_Bitewings,
];

export const StudyPreview = (props: StudyPreviewProps) => {
  const { study } = props;

  const imagePreviewRef = useRef<HTMLDivElement>(null);

  const { formatMessage, formatDate } = useIntl();

  const { isPhone } = useMedia();

  const reports = useAppSelector(
    reportsModel.selectors.selectByStudyID(study.ID),
  );

  const firstStudyReport = first(
    // Filter panowing repots for IOXRay studies
    study.Type === StudyType.StudyType_IntraoralXRay
      ? reports.filter(
          (report) => report.Type !== ReportType.ReportType_Pano_Bitewings,
        )
      : reports.filter((report) => ALLOWED_REPORT_TYPES.includes(report.Type)),
  );

  const previewImageSrc = getImageSrc(
    firstStudyReport?.DisplayAssetID || study.DisplayAssetID,
    'preview',
  );

  const studyType = study.Type;

  const showUploadingStatusFrame =
    study.Status !== StudyStatus.StudyStatus_Finished;

  const previewType = useMemo(() => {
    if (showUploadingStatusFrame) {
      return 'uploadingStatusFrame';
    }

    if (
      studyType === StudyType.StudyType_PanoramicXRay &&
      firstStudyReport?.Specific.case === 'DataPanoBitewings'
    ) {
      return 'panowingsPreview';
    }

    if (studyType === StudyType.StudyType_IntraoralXRay) {
      return 'IOXRayPreview';
    }

    return 'simplePreview';
  }, [studyType, firstStudyReport, showUploadingStatusFrame]);

  const isIOXRayOrPanowingsPreview =
    previewType === 'IOXRayPreview' || previewType === 'panowingsPreview';

  const showStudyPreview =
    previewType !== 'uploadingStatusFrame' && previewImageSrc;

  const backgroundStyles = showStudyPreview
    ? styles.blackBackground
    : styles.whiteBackground;

  const imgTextColorStyle = showStudyPreview
    ? styles.imgTextWhite
    : styles.imgTextBlack;

  return (
    <div
      ref={imagePreviewRef}
      className={cn(styles.imgContainer, backgroundStyles)}
    >
      {!showStudyPreview && <UploadingStatusFrame study={study} />}

      {showStudyPreview && (
        <img
          className={cn(
            styles.img,
            isIOXRayOrPanowingsPreview && styles.IOXRayOrPanowingsPreview,
          )}
          onError={ensureRefreshImageSrc}
          src={previewImageSrc}
          alt={formatMessage(i18n.studyTypes[study.Type])}
        />
      )}

      {studyType === StudyType.StudyType_IntraoralXRay ? (
        <>
          <div className={styles.layer}>
            <div className={styles.info}>
              <h5
                className={cn(
                  styles.reportType,
                  isPhone ? 'h6' : 'h5',
                  imgTextColorStyle,
                )}
              >
                {formatMessage(studyCardTitle[studyType])}
              </h5>

              {study?.Created?.At && (
                <span
                  className={cn(
                    styles.date,
                    isPhone ? 'p4' : 'p3',
                    imgTextColorStyle,
                  )}
                >
                  {formatDate(study.Created.At.toDate(), {
                    dateStyle: 'medium',
                  })}
                </span>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={cn(styles.imgText)}>
          <h5 className={cn('h5', imgTextColorStyle)}>
            {formatMessage(studyCardTitle[studyType])}
          </h5>

          {study?.Created?.At && (
            <span className={cn('p3', imgTextColorStyle)}>
              {formatDate(study.Created.At.toDate(), {
                dateStyle: 'medium',
              })}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
