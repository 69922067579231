import { FC, useState, useEffect, ImgHTMLAttributes } from 'react';

import { Skeleton } from '@/shared/ui';

type ImageWithSkeletonProps = ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  alt: string;
  skeletonWidth?: number;
  skeletonHeight?: number;
  className?: string;
};

export const ImageWithSkeleton: FC<ImageWithSkeletonProps> = (props) => {
  const { className, src, alt, skeletonWidth, skeletonHeight, ...rest } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true); // Сбрасываем состояние загрузки при смене изображения
  }, [src]);

  return (
    <>
      {loading && (
        <Skeleton
          width={skeletonWidth}
          height={skeletonHeight}
          borderRadius="6px"
        />
      )}

      <img
        src={src}
        alt={alt}
        className={className}
        onLoad={() => setLoading(false)}
        {...rest}
      />
    </>
  );
};
