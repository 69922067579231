import { range } from 'lodash-es';

import { JawQuartiles, JawQuartilesRanges } from './types';

export const jawQuartilesRanges: JawQuartilesRanges = {
  [JawQuartiles.topLeft]: range(18, 10),
  [JawQuartiles.topRight]: range(21, 29),
  [JawQuartiles.bottomLeft]: range(48, 40),
  [JawQuartiles.bottomRight]: range(31, 39),
  topLeftPrimary: range(55, 50),
  topRightPrimary: range(61, 66),
  bottomLeftPrimary: range(85, 80),
  bottomRightPrimary: range(71, 76),
};

export const UPPER_JAW_PERMANENT = [
  ...jawQuartilesRanges.topLeft,
  ...jawQuartilesRanges.topRight,
];

export const UPPER_JAW_PRIMARY = [
  ...jawQuartilesRanges.topLeftPrimary,
  ...jawQuartilesRanges.topRightPrimary,
];

export const LOWER_JAW_PERMANENT = [
  ...jawQuartilesRanges.bottomLeft,
  ...jawQuartilesRanges.bottomRight,
];

export const LOWER_JAW_PRIMARY = [
  ...jawQuartilesRanges.bottomLeftPrimary,
  ...jawQuartilesRanges.bottomRightPrimary,
];
