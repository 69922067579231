import * as process from 'process';

import { debug } from '../lib/debug';

export const DEBUG_MODE = localStorage.getItem('DEBUG_MODE') === 'true';

debug({
  env: import.meta.env,
  'process.env': process.env,
  REACT_APP_AVAILABLE_LANGS: __REACT_APP_AVAILABLE_LANGS__,
  REACT_APP_BASE_URI: __REACT_APP_BASE_URI__,
  REACT_APP_ROOT_URI: __REACT_APP_ROOT_URI__,
  REACT_APP_BASE_URI_BLACK_CAT: __REACT_APP_BASE_URI_BLACK_CAT__,
  REACT_APP_CORS_POLICY: __REACT_APP_CORS_POLICY__,
  APP_VERSION: __APP_VERSION__,
});

export const BASE_URI =
  __REACT_APP_ROOT_URI__ || __REACT_APP_BASE_URI__ || window.location.origin;
export const ENVIRONMENT = import.meta.env.MODE || 'development';
export const APP_VERSION = __APP_VERSION__;
export const IS_DEVELOP_FEATURE = ENVIRONMENT === 'development';
export const BASE_URI_BLACK_CAT = __REACT_APP_BASE_URI_BLACK_CAT__;

// sentry variables
export const SENTRY_DSN = __REACT_APP_SENTRY_DSN__ || '';
export const SENTRY_TRACES_SAMPLE_RATE =
  parseFloat(__REACT_APP_SENTRY_TRACE_RATE__) || 0.2;
export const ERROR_TRACKING_ENABLED_ENVIRONMENTS = ['production', 'staging'];
export const IS_ERROR_TRACKING_ENABLED =
  ERROR_TRACKING_ENABLED_ENVIRONMENTS.includes(ENVIRONMENT);

export const CORS_POLICY =
  (__REACT_APP_CORS_POLICY__ as RequestCredentials) || 'same-origin';

export const TYPEWRITER_TIMEOUT = 6000;
