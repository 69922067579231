import React, { FC, useCallback, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash-es';

import { Icon } from '../Icon/Icon';

import styles from './UploadImageInput.module.scss';

type UploadImageInputProps = {
  className?: string;
  title?: string | React.ReactNode;
  dropDescription?: string | React.ReactNode;
  image: FileWithPath;
  onChange: (image: File) => void;
  hideImageDescription?: boolean;
  previewImage?: string;
  isDeletable?: boolean;
  onRemove?: () => void;
};

export const UploadImageInput: FC<UploadImageInputProps> = (props) => {
  const {
    title,
    dropDescription,
    onChange,
    image,
    className,
    previewImage,
    hideImageDescription,
    isDeletable = true,
    onRemove,
  } = props;

  const { formatMessage } = useIntl();

  const [isPreviewDeleted, setIsPreviewDeleted] = useState(false);

  const bytesToMegaBytes = (bytes: number) =>
    `${(bytes / (1024 * 1024)).toFixed(2)} MB`;

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      onChange(acceptedFiles[0]);
    },
    [image],
  );

  const handleRemove: React.MouseEventHandler<SVGSVGElement> = (event) => {
    event.stopPropagation();

    if (onRemove && previewImage) {
      setIsPreviewDeleted(true);
      onRemove();
    }

    onChange({} as File);
  };

  const { getInputProps, getRootProps, isFocused } = useDropzone({
    onDrop,
  });

  const imageDescription = !hideImageDescription && (
    <div className={styles.imageDescription}>
      <Icon className={styles.docIcon} name="doc" size={24} />
      <p>{image?.path}</p>
      <p className={styles.imageSize}>{bytesToMegaBytes(image?.size)}</p>
    </div>
  );

  const hasImage = !isEmpty(image);

  const showDeleteButton =
    isDeletable && (hasImage || (!isPreviewDeleted && previewImage));
  const showPreviewImage = previewImage && !isPreviewDeleted && !hasImage;
  const showDropDescription = !hasImage && (!previewImage || isPreviewDeleted);

  return (
    <div {...getRootProps()} className={cn(className, styles.container)}>
      <input {...getInputProps()} />
      <p className="p2">{title}</p>
      <div
        className={cn(styles.dragDescription, isFocused && styles.isFocused)}
      >
        {showPreviewImage && (
          <img
            src={previewImage}
            alt={formatMessage({
              id: 'uploadImage.imagePreview',
              defaultMessage: 'Image Preview',
            })}
          />
        )}
        {hasImage && (
          <div className={styles.image}>
            {imageDescription}

            <img
              src={URL.createObjectURL(image)}
              alt={formatMessage({
                id: 'uploadImage.imagePreview',
                defaultMessage: 'Image Preview',
              })}
            />
          </div>
        )}
        {showDropDescription && dropDescription}
      </div>
      {showDeleteButton && (
        <Icon
          className={styles.removeIcon}
          name="delete"
          size={32}
          onClick={handleRemove}
        />
      )}
    </div>
  );
};
