import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button, WidgetCard } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { BASE_URI_BLACK_CAT } from '@/shared/config';

import { patientModel } from '@/entities/patient';

import styles from './PatientHistoricalData.module.scss';

type PatientHistoricalDataProps = {
  patientID: string;
};

export const PatientHistoricalData: FC<PatientHistoricalDataProps> = (
  props,
) => {
  const { patientID } = props;

  const blackCatPatientID = useAppSelector(
    patientModel.selectors.selectBlackCatPatientID(patientID),
  );

  if (blackCatPatientID === 0 || blackCatPatientID === undefined) {
    return null;
  }

  const redirectToBlackCat = () => {
    const url = `${BASE_URI_BLACK_CAT}/patients/${blackCatPatientID}/`;

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <WidgetCard className={cn(styles.container)}>
      <h4 className={cn(styles.title, 'h4')}>
        <FormattedMessage
          id="patientInfo.historicalData"
          defaultMessage="Patient historical data"
        />
      </h4>

      <Button
        variant="secondary"
        size="medium"
        onClick={redirectToBlackCat}
        className={styles.redirectButton}
      >
        <FormattedMessage
          id="patientInfo.openOldReports"
          defaultMessage="Open old reports"
        />
      </Button>
    </WidgetCard>
  );
};
